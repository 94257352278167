import { faChevronDown, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setPageSelected as setPageSelectedClearances } from '../../../../clearances/redux/reducers/shipmentGridSettingsSlice';
import { resetStatus } from '../../../../clearances/redux/reducers/shipmentStatusSlice';
import { setPageSelected as setPageSelectedProfile } from '../../../../profile/redux/reducers/profileGridSettingsSlice';
import { setPageSelected as setPageSelectedSubscribers } from '../../../../subscribers/redux/reducers/subscribersGridSettingsSlice';
import {
    GetClientSelectionSearch,
    GetClientSelectionSettings,
    UpdateClientSelectionSettings
} from '../../../redux/actions/clientSelection';
import { usePortalDispatch, usePortalSelector } from '../../../redux/hooks';
import {
    resetClientSelectionState,
    setClientSelectionCountry,
    setClientSelectionServices,
    setIsApplyClicked
} from '../../../redux/reducers/clientSelectionReducer';
import { REACT_APP_ASSETS_URL } from '../../constants/constants-portal';
import { useDebounce } from '../../hooks/useDebounce';
import useDropdownToggle from '../../hooks/useDropdownToggle';
import useTranslation from '../../hooks/useTranslation';
import Spinner from '../Spinner';
import useToast from '../Toast/useToast';
import './clientChooser.css';
import { convertSelectedClients } from './utils';

export interface ClientChooserRowOption {
    name: string;
    selected: boolean;
    caption: string;
    fullNumber: string;
}

export interface ClientChooserRow {
    name: string;
    country: 'ca' | 'us';
    number: string;
    fullNumber: string;
    options: ClientChooserRowOption[];
}

const ClientChooser = () => {
    const dispatch = usePortalDispatch();
    const translate = useTranslation();
    const location = useLocation();
    const { send: sendToast } = useToast();

    const { clientSelection, initialClientSelection, isLoadingGet, isLoadingSearch, isLoadingUpdate } = usePortalSelector(
        (state) => state.clientSelection
    );

    const searchInputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const { isOpen, handleToggle } = useDropdownToggle(dropdownRef);

    const [setClientSelectorHeight, setStyle] = useState({ maxHeight: '80vh' });

    const [searchQuery, setSearchQuery] = useState<string>('');
    const debouncedQuery = useDebounce(searchQuery, 1000);

    // Variables

    const isUnrestricted = clientSelection?.HasUnrestrictedAccess || clientSelection?.ServicesLimitReached || false;

    const selectedCountryCodeInitial = initialClientSelection?.Countries.find((c) => c.IsSelected)?.Code;
    const totalClientsAmountInitial = initialClientSelection?.ClientServices.filter(
        (c) => c.CountryCode === selectedCountryCodeInitial
    ).length;
    const selectedClientsAmountInitial = initialClientSelection?.ClientServices.filter(
        (c) => c.IsSelected && c.CountryCode === selectedCountryCodeInitial
    ).length;

    const selectedCountryCode = clientSelection?.Countries.find((c) => c.IsSelected)?.Code;

    const clientSelectionUS = clientSelection?.ClientServices.filter((c) => c.CountryCode === 'us');
    const clientSelectionCA = clientSelection?.ClientServices.filter((c) => c.CountryCode === 'ca');
    const displayClients = {
        ca: clientSelectionCA,
        us: clientSelectionUS
    }[selectedCountryCode || 'ca'];

    const searchedDisplayClients = displayClients
        ? displayClients.filter(
              (c) =>
                  c.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  c.FullNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  c.IsSelected
          )
        : [];

    const convertedDisplayClients = convertSelectedClients((isUnrestricted ? displayClients : searchedDisplayClients) || []);
    const selectedClientsAmount = displayClients?.filter((c) => c.IsSelected).length || 0;

    useEffect(() => {
        dispatch(GetClientSelectionSettings());
    }, [dispatch]);

    useEffect(() => {
        const handleWindowResize = () => {
            if (window.matchMedia('(max-width: 768px)').matches) {
                setStyle({ maxHeight: '100vh' });
            } else {
                setStyle({ maxHeight: '80vh' });
            }
        };

        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    useEffect(() => {
        if (isUnrestricted) {
            if (searchQuery.trim().length > 3) {
                searchInputRef.current?.blur();

                dispatch(
                    GetClientSelectionSearch({
                        countryCode: selectedCountryCode || 'ca',
                        query: searchQuery
                    })
                );
            } else if (searchQuery.length === 0) {
                // if(!displayClients) return;
                // const updatedClients = [ ...displayClients ].filter((c) => c.IsSelected);
                // dispatch(setClientSelectionServices(updatedClients));
            }
        }
    }, [debouncedQuery, dispatch]);

    const handleSearch = (val: string) => {
        setSearchQuery(val);
    };

    const handleSubmit = async () => {
        if (!clientSelection) return;

        const clientServices = !isUnrestricted
            ? clientSelection?.ClientServices
            : clientSelection?.ClientServices.filter((c) => c.IsSelected);

        const res = (await dispatch(
            UpdateClientSelectionSettings({
                ServicesLimitReached: clientSelection?.ServicesLimitReached,
                MaxSelectedServicesPerCountry: clientSelection?.MaxSelectedServicesPerCountry,
                HasUnrestrictedAccess: isUnrestricted,
                Countries: clientSelection?.Countries,
                ClientServices: clientServices
            })
        )) as any;

        await dispatch(GetClientSelectionSettings());

        dispatch(setIsApplyClicked(true));

        if (res?.meta?.requestStatus) {
            sendToast({ message: translate('ClientSelectionUpdated_Label'), type: 'success' });
        }

        handleToggle();
        setSearchQuery('');

        // For clearances grid
        if (location.pathname.includes('/Clearances')) {
            dispatch(setPageSelectedClearances(1));
            dispatch(resetStatus());
        }

        // For subscribers grid
        if (location.pathname.includes('/Subscribers')) {
            dispatch(setPageSelectedSubscribers(1));
        }

        // For profile grid
        if (location.pathname.includes('/Profile')) {
            dispatch(setPageSelectedProfile(1));
        }
    };

    const handleCancel = async () => {
        handleToggle();
        setSearchQuery('');
        dispatch(resetClientSelectionState());
    };

    const selectCountry = (code: 'us' | 'ca') => {
        dispatch(setClientSelectionCountry(code));
        setSearchQuery('');
    };

    const deselectAll = () => {
        if (!displayClients || !clientSelection?.ClientServices) return false;

        const newClients = [...clientSelection?.ClientServices];

        const updatedClients = newClients.map((c) => ({ ...c, IsSelected: c.CountryCode === selectedCountryCode ? false : c.IsSelected }));

        dispatch(setClientSelectionServices(updatedClients));
    };

    const selectAll = () => {
        if (!displayClients || !clientSelection?.ClientServices) return false;

        const toBeSelectedClients = isUnrestricted
            ? displayClients
            : displayClients.filter(
                  (c) =>
                      c.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      c.FullNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      c.IsSelected
              );

        // Limit for amount of clients selected at once
        if (toBeSelectedClients.length > (clientSelection?.MaxSelectedServicesPerCountry || 250)) {
            return sendToast({
                message: translate('ClientSelectionLimitWarning_Label', clientSelection?.MaxSelectedServicesPerCountry || 250),
                type: 'error'
            });
        }

        const newClients = [...clientSelection?.ClientServices];

        const selectedAmount = toBeSelectedClients.filter((c) => c.IsSelected).length;
        const nextValue = selectedAmount === toBeSelectedClients.length ? false : true;

        const updatedSearched = searchedDisplayClients.map((c) => ({
            ...c,
            IsSelected: nextValue
        }));

        const updatedClients = isUnrestricted
            ? newClients.map((c) => ({ ...c, IsSelected: nextValue }))
            : newClients.map((c) => {
                  const exists = updatedSearched.find((c2) => c2.FullNumber === c.FullNumber);

                  return {
                      ...c,
                      IsSelected: exists ? exists.IsSelected : c.IsSelected
                  };
              });

        dispatch(setClientSelectionServices(updatedClients));
    };

    // Are any clients selected at all?
    const checkAllAnySelection = () => {
        if (!displayClients) return false;

        const selectedAmount = displayClients.filter((c) => c.IsSelected).length;

        return selectedAmount <= displayClients.length && selectedAmount >= 1;
    };

    // Are any clients selected but not all?
    const checkAllPartialSelection = () => {
        if (!displayClients) return false;

        const selectedAmount = displayClients.filter((c) => c.IsSelected).length;

        return selectedAmount < displayClients.length && selectedAmount >= 1;
    };

    // Are any client options selected at all?
    const checkClientAnySelection = (client: ClientChooserRow) => {
        if (!client.options) return false;

        const selectedAmount = client.options.filter((c) => c.selected).length;
        return selectedAmount >= 1;
    };

    // Are some client options selected but not all?
    const checkClientPartialSelection = (client: ClientChooserRow) => {
        if (!client.options) return false;

        const selectedAmount = client.options.filter((c) => c.selected).length;
        return selectedAmount < client.options.length && selectedAmount >= 1;
    };

    // Toggle entire client
    const toggleClient = (client: ClientChooserRow) => {
        if (!clientSelection?.ClientServices || !client.options) return;

        // Limit for amount of clients selected at once
        if (
            selectedClientsAmount + client.options.length > (clientSelection?.MaxSelectedServicesPerCountry || 250) &&
            !checkClientAnySelection(client)
        ) {
            return sendToast({
                message: translate('ClientSelectionLimitWarning_Label', clientSelection?.MaxSelectedServicesPerCountry || 250),
                type: 'error'
            });
        }

        const newClients = [...clientSelection?.ClientServices];

        const selectedAmount = client.options.filter((c) => c.selected).length;
        const nextValue = selectedAmount === client.options.length ? false : true;

        const updatedClients = newClients.map((c) => ({ ...c, IsSelected: c.Number === client.number ? nextValue : c.IsSelected }));

        dispatch(setClientSelectionServices(updatedClients));
    };

    // Toggle one client option
    const toggleOption = (client: ClientChooserRow, option: ClientChooserRowOption) => {
        if (!clientSelection?.ClientServices || !client.options) return;

        // Limit for amount of clients selected at once
        if (
            selectedClientsAmount >= (clientSelection?.MaxSelectedServicesPerCountry || 250) &&
            !client.options.find((o) => o.fullNumber === option.fullNumber)?.selected
        ) {
            return sendToast({
                message: translate('ClientSelectionLimitWarning_Label', clientSelection?.MaxSelectedServicesPerCountry || 250),
                type: 'error'
            });
        }

        const newClients = [...clientSelection?.ClientServices];

        const updatedClients = newClients.map((c) => ({
            ...c,
            IsSelected: c.FullNumber === option.fullNumber ? !c.IsSelected : c.IsSelected
        }));

        dispatch(setClientSelectionServices(updatedClients));
    };

    const initialClientsSelectedLabel = translate('ClientsSelected_Label', selectedClientsAmountInitial, totalClientsAmountInitial);

    const initialClientsSelectedLabelSplit = typeof initialClientsSelectedLabel === 'string' ? initialClientsSelectedLabel?.split(' ') : [];

    const clientsSelectedLabel = translate(
        'ClientsSelected_Label',
        displayClients?.filter((c) => c.IsSelected).length,
        isUnrestricted ? displayClients?.length : searchedDisplayClients?.length
    );

    const clientsSelectedLabelSplit = typeof clientsSelectedLabel === 'string' ? clientsSelectedLabel?.split(' ') : [];

    // const isButtonEnabled = updatedState?.ClientServices.filter((c) =>
    //     c.country === selectedCountryCode &&
    //     c.options.find((o) => o.selected)
    // ).length;

    // Display nothing while data is loading
    if (!clientSelection && isLoadingGet) return <></>;

    return (
        <>
            <div className={'dropdown mr-1 mr-md-0'}>
                {/* Dropdown Button */}
                <div
                    className={'btn btn-tertiary dropdown-toggle d-flex flex-row align-items-center'}
                    // id={'dropdownMenuButton'}
                    // data-toggle={'dropdown'}
                    // aria-haspopup={'true'}
                    // aria-expanded={'false'}
                    onClick={handleToggle}
                >
                    <span className={'dropdown-btn-flag'}>
                        {selectedCountryCodeInitial && (
                            <img
                                src={`${REACT_APP_ASSETS_URL}/assets/images/icons/flags/${selectedCountryCodeInitial}-flag.svg`}
                                alt='USA flag'
                            />
                        )}
                    </span>
                    <span>
                        {initialClientsSelectedLabelSplit[0]}{' '}
                        {window.innerWidth > 768
                            ? `${selectedCountryCodeInitial?.toUpperCase()} ${initialClientsSelectedLabelSplit[1]} ${
                                  initialClientsSelectedLabelSplit[2]
                              }`
                            : ''}
                    </span>
                </div>

                {isOpen && (
                    <div
                        className={`dropdown-menu dropdown-menu-right clientchooser-dropdown p-0 ${
                            window.innerWidth < 768 ? 'dropdown-full-screen-mobile show' : ''
                        }`}
                        ref={dropdownRef}
                    >
                        <div className={'card shadow-none d-flex flex-column h-100'} style={{ ...setClientSelectorHeight, width: '100%' }}>
                            {/* Loading state when searching for clients */}
                            {isLoadingSearch && (
                                <div className='w-100 h-100 d-flex align-items-center justify-content-center clientchooser-loading-search'>
                                    <Spinner />
                                </div>
                            )}

                            {/* Select country */}
                            <div className={'border-bottom flex-grow-0 px-4 py-3'}>
                                <h5 className={'mb-2'}>{translate('SelectCountry_Label')}</h5>
                                {/* Country Switcher */}
                                <div
                                    className={'custom-toggle country-switcher d-flex flex-row justify-content-center'}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <label className={'switch w-100 mb-0'}>
                                        <input type={'checkbox'} value={'true'} />
                                        <span className={'slider round clientchooser-before-disable'}>
                                            <span
                                                className={`clientchooser-active clientchooser-active-${
                                                    clientSelection?.Countries.find((c) => c.IsSelected)?.Code
                                                }`}
                                            ></span>

                                            <div className={'options'}>
                                                {clientSelection?.Countries.map((c) => {
                                                    return (
                                                        <span
                                                            key={c.Code}
                                                            className={'label font-weight-bold'}
                                                            onClick={() => selectCountry(c.Code)}
                                                        >
                                                            <div className={'d-flex flex-row align-items-center justify-content-center'}>
                                                                <div className={'flag'}>
                                                                    <img
                                                                        src={`${REACT_APP_ASSETS_URL}/assets/images/icons/flags/${c.Code}-flag.svg`}
                                                                        alt={`${c.Name} flag`}
                                                                    />
                                                                </div>
                                                                {
                                                                    {
                                                                        ca: 'Canada',
                                                                        us: 'USA'
                                                                    }[c.Code]
                                                                }
                                                            </div>
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                        </span>
                                    </label>
                                </div>
                                {/* end Country Switcher */}
                            </div>

                            {/* Search Bar */}
                            <div className={'border-bottom bg-gray-200 flex-grow-0 px-4 py-3'}>
                                <div className={'search-bar-wrapper align-self-center flex-md-grow-1'}>
                                    <div className={'search-bar'}>
                                        <div className={'input-group form-group align-items-center position-relative m-0'}>
                                            <input
                                                type={'text'}
                                                className={'main-desktop-input form-control search-input rounded-sm'}
                                                placeholder={translate('SearchClients_Label')}
                                                ref={searchInputRef}
                                                value={searchQuery}
                                                onChange={(e) => handleSearch(e.target.value)}
                                            />
                                            <div className={'magnifying-glass-input-icon'}>
                                                <FontAwesomeIcon icon={faSearch} />
                                            </div>
                                            <div className={'position-relative'}>
                                                <span className={'main-desktop-form-clear form-clear d-none'}>
                                                    <FontAwesomeIcon icon={faTimesCircle} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {isUnrestricted && (
                                        <p className={'search-bar-subtext small'}>{translate('UnrestrictedMessage_Label')}</p>
                                    )}
                                </div>
                            </div>
                            {/* Selection Options */}
                            <div className={'border-bottom bg-gray-200 flex-fill px-4 py-2 h-100 overflow-auto'}>
                                <div className={'d-flex border-bottom py-2'}>
                                    <div className={'custom-control custom-checkbox'}>
                                        <input
                                            type={'checkbox'}
                                            className={'custom-control-input'}
                                            id={'customCheck1'}
                                            onChange={(e) => selectAll()}
                                            checked={checkAllAnySelection()}
                                        />
                                        <label
                                            className={`custom-control-label ${checkAllPartialSelection() ? 'indeterminate' : ''}`}
                                            htmlFor={'customCheck1'}
                                        >
                                            {translate('NotificationsSelectAllNotifications_Label')}
                                        </label>
                                    </div>
                                    <span className={'breadcrumb-item pl-3'}>
                                        <span className={'deselect-link'} onClick={() => deselectAll()}>
                                            {translate('NotificationsDeselectAllNotifications_Label')}
                                        </span>
                                    </span>
                                    <span className={'flex-grow-1 text-right'}>
                                        {<b>{clientsSelectedLabelSplit?.[0]}</b>} {clientsSelectedLabelSplit?.[1]}{' '}
                                        {clientsSelectedLabelSplit?.[2]}
                                    </span>
                                </div>

                                {/* Accordions. Wrap in a form tag to prevent dropdown from closing when an acccordion is clicked */}
                                <div className={'accordion border-bottom'} id={'clients-accordion'}>
                                    {convertedDisplayClients &&
                                        convertedDisplayClients
                                            // Sort by number
                                            .sort((a, b) => parseInt(a.number) - parseInt(b.number)) // Sort by number
                                            .map((client, clientIndex) => (
                                                <div className={'accordion-item border-bottom py-2'} key={clientIndex}>
                                                    <div
                                                        className='d-flex justify-content-between align-items-center collapsed'
                                                        data-toggle={'collapse'}
                                                        data-target={`#collapse-${clientIndex}`}
                                                        aria-expanded={'false'}
                                                        aria-controls={`#collapse-${clientIndex}`}
                                                    >
                                                        <div className={'d-flex flex-direction-row'}>
                                                            <form>
                                                                <div className={'custom-control custom-checkbox'}>
                                                                    <input
                                                                        type={'checkbox'}
                                                                        className={`custom-control-input ${
                                                                            checkClientPartialSelection(client) ? 'indeterminate' : ''
                                                                        }`}
                                                                        id={`client-check-${clientIndex}`}
                                                                        checked={checkClientAnySelection(client)}
                                                                        onChange={(e) => toggleClient(client)}
                                                                    />
                                                                    <label
                                                                        className={`custom-control-label ${
                                                                            checkClientPartialSelection(client) ? 'indeterminate' : ''
                                                                        }`}
                                                                        htmlFor={`client-check-${clientIndex}`}
                                                                    />
                                                                </div>
                                                            </form>
                                                            <span>{client.number + ' - ' + client.name}</span>
                                                        </div>
                                                        <div className='chevron-down'>
                                                            <FontAwesomeIcon icon={faChevronDown} />
                                                        </div>
                                                    </div>
                                                    <div id={`collapse-${clientIndex}`} className={'collapse'}>
                                                        <div className={'ml-4 mt-2'}>
                                                            {client.options.map((option, optionIndex) => (
                                                                <div key={`${clientIndex}-${optionIndex}`} className='py-1'>
                                                                    <div className={'custom-control custom-checkbox'}>
                                                                        <input
                                                                            type={'checkbox'}
                                                                            className={'custom-control-input'}
                                                                            id={`client-option-check-${clientIndex}-${optionIndex}`}
                                                                            checked={option.selected}
                                                                            onChange={() => toggleOption(client, option)}
                                                                        />
                                                                        <label
                                                                            className={'custom-control-label'}
                                                                            htmlFor={`client-option-check-${clientIndex}-${optionIndex}`}
                                                                        >
                                                                            {option.caption + ' - ' + option.name}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                </div>
                                {/* end Accordions */}
                            </div>
                            {/* Action Buttons */}
                            <div className={'d-flex justify-content-center flex-grow-0 px-4 pt-4 pb-3'}>
                                <button type={'button'} className={'btn btn-secondary btn-md flex-grow-1 mr-1 w-50'} onClick={handleCancel}>
                                    {translate('Cancel_Label')}
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    type={'button'}
                                    className={`
                                    btn btn-primary btn-md flex-grow-1 ml-1 w-50
                                    ${isLoadingGet || isLoadingUpdate ? 'disabled' : ''}
                                `}
                                    // disabled={!isButtonEnabled}
                                >
                                    {isLoadingGet || isLoadingUpdate ? <Spinner size='small' color='white' /> : translate('Apply_Label')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ClientChooser;
