import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { REACT_APP_ASSETS_URL_CSS } from '../portal/common/constants/constants-portal';
import ToastProvider from '../portal/common/features/Toast/ToastProvider';
import App from './App';
import store from './redux/store';

const Portal = () => {
    return (
        <Provider store={store}>
            <ToastProvider>
                <HelmetProvider>
                    <Helmet>
                        <title>Portal</title>
                        <link rel='stylesheet' href={REACT_APP_ASSETS_URL_CSS} />
                        <style type='text/css'>{`
                        .k-grid-header {
                            padding-right: 18px !important;
                        }
                        `}</style>
                    </Helmet>
                    <App />
                </HelmetProvider>
            </ToastProvider>
        </Provider>
    );
};

export default Portal;
