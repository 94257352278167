import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../../portal/common/features/Spinner';
import useKendoStickyScrollbar from '../../../../portal/common/hooks/useKendoStickyScrollbar';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { HydrationSlice } from '../../../../portal/common/models/ReduxSlices';
import { DETAILS_LABELS } from '../../../common/constants/detailsLabelsMap';
import { DocumentsSlice } from '../../../common/models/ReduxSlices';
import { GetLineDetails } from '../../../redux/actions/clearanceDocuments';
import { resetLineDetails } from '../../../redux/reducers/documentsSlice';
import { RootState } from '../../../redux/store';
import NoDataResponse from './NoDataResponse';
import { reorderLineDetails } from './reorderLineDetails';

interface LineDetailsProps {
    fileNo: string;
}

const LineDetails: React.FC<LineDetailsProps> = ({ fileNo }) => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { lineDetails, isLoadingLineDetails } = useSelector<RootState, DocumentsSlice>((state) => state.clearanceDocuments);
    const tableRef = useRef(null);
    const [result, setResult] = useState<any>({});
    const [dataState, setDataState] = useState<any>({});
    const [isOverLimit, setIsOverLimit] = useState<boolean>(false);
    const dispatch = useDispatch();
    const translate = useTranslation();

    useKendoStickyScrollbar(tableRef);

    const reorderedLineDetails = lineDetails ? reorderLineDetails(lineDetails) : [];

    const boldRows = ['Gross Weight', 'Entered Value', 'Value'];

    const createDataState = (dataState: any) => {
        if (!reorderedLineDetails?.length) return null;

        const keys = Object.keys(reorderedLineDetails?.[0]).filter((item) => item !== 'LineNo' && item !== 'RealLineNo') as string[];

        const transposedArr = keys.map((key) => {
            const obj = { LineDetails: DETAILS_LABELS?.[key] } as any;

            reorderedLineDetails.forEach((item: any) => {
                obj[item['LineNo']] = item[key];
            });

            return obj;
        });
        return {
            result: process(transposedArr.slice(0), dataState),
            dataState: dataState
        };
    };

    const cellStyling = (props: any, field: any) => {
        const isBold = boldRows.includes(props.dataItem.LineDetails);
        return <td className={isBold ? 'font-weight-bold' : ''}>{props.dataItem[field] === '' ? '-' : props.dataItem[field]}</td>;
    };

    useEffect(() => {
        if (!lineDetails) return;

        const initialState = createDataState({
            take: 50,
            skip: 0
        });

        setResult(initialState?.result);
        //Checks if data contains index over the limit
        setIsOverLimit(lineDetails.length > Number(user?.PortalClearanceLineDetailsLinesMaxCount));
    }, [lineDetails]);

    useEffect(() => {
        dispatch(GetLineDetails({ fileNo }));
    }, [dispatch, fileNo]);

     useEffect(() => {
        return () => {
            dispatch(resetLineDetails());
        }
    }, [dispatch])

    // Loading state
    if (isLoadingLineDetails)
        return (
            <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                <Spinner />
                {/* <GridLoadingOverlay parentElement='.k-grid.line-details-table' /> */}
            </div>
        );

    if (!isLoadingLineDetails && !result?.data)
        return (
            <div className='container-fluid d-flex flex-column justify-content-center align-items-center flex-fill mb-5'>
                <NoDataResponse labelToDisply='LineDetailsNoDataFound_Label' />
            </div>
        );

    return (
        <div ref={tableRef}>
            {result && result?.data ? (
                <Grid
                    id='line-details-table'
                    className='line-details-table flex-fill'
                    data={result.data}
                    {...dataState}
                    total={result.data.length}
                >
                    <Column
                        field='LineDetails'
                        title={translate('LineNumber_Label')}
                        locked
                        width={'220px'}
                        cell={(props: any) => {
                            return (
                                <td>
                                    {translate(
                                        props.dataItem['LineDetails']
                                            .split(' ')
                                            .map((x: any) => {
                                                return x[0].toUpperCase() + x.substring(1);
                                            })
                                            .join('')
                                    )}
                                </td>
                            );
                        }}
                    />

                    {reorderedLineDetails &&
                        reorderedLineDetails.map((line, index) => {
                            if (index < Number(user?.PortalClearanceLineDetailsLinesMaxCount))
                                return (
                                    <Column
                                        key={index}
                                        field={`${line['LineNo']}`}
                                        title={`${translate('LineNo_Label')} ${line?.RealLineNo ?? ''}`}
                                        cell={(props: any) => cellStyling(props, line['LineNo'])}
                                        width={'180px'}
                                    />
                                );
                        })}
                    {isOverLimit && (
                        <Column
                            field=''
                            title={''}
                            width={'180px'}
                            //headerStyle={{ display: 'none' }}
                            cell={(props: any) => {
                                if (props.dataIndex === 0) {
                                    return <td>{translate('AditionalLinesAvailable_Label')}</td>;
                                } else {
                                    return <td></td>;
                                }
                            }}
                        />
                    )}
                </Grid>
            ) : null}
            <div className='sticky-scrollbar'>
                <div className='empty-element'></div>
            </div>
        </div>
    );
};

export default LineDetails;
