import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getter, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import {
    GridColumn as Column,
    Grid,
    GridCellProps,
    GridColumnMenuWrapper,
    GridHeaderCellProps,
    GridSortChangeEvent
} from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGridLanguage from '../../../../portal/common/hooks/useGridLanguage';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { Document } from '../../../../portal/common/models/ClearanceDocuments';
import { ClientSelectionSlice, LanguageSlice } from '../../../../portal/common/models/ReduxSlices';
import { setEllipsisEntryNo } from '../../../../portal/redux/reducers/ellipsisSlice';
import { addViewImagesData } from '../../../../portal/redux/reducers/viewImagesSlice';
import { setDateDropdownLabel } from '../../../redux/reducers/dateFilterSlice';
import { setCheckedDocuments } from '../../../redux/reducers/docTypeSlice';
import { RootState } from '../../../redux/store';
import DocumentsFooter from '../../../screens/DocumentsFooter';
import { generateGridKey } from '../../../utils/imaging-utils';
import { ButtonArray, calculateDateRange, DocumentsTypeFilterCA, DocumentsTypeFilterUS } from '../../constants/constants-documents';
import { GroupedDocuments } from '../../models/ImagingModel';
import { DateFilterSlice, ImagingModelSlice } from '../../models/ReduxSlices';
import { ColumnMenuCheckboxFilter, ColumnMenuDateFilter } from './ColumnMenu';
import FormattedGridCell from './FormattedGridCell';

interface EntryDocumentsTableProps {
    entryNo: string;
    downloadButtonClick: (value: string) => void;
    setDownloadModalData: (value: string[]) => void;
    setModalView: (value: null | string) => void;
    setDocumentDetailsCompareView: () => void;
}

const EntryDocumentsTable: React.FC<EntryDocumentsTableProps> = ({
    entryNo,
    downloadButtonClick,
    setDownloadModalData,
    setModalView,
    setDocumentDetailsCompareView
}) => {
    const SELECTED_FIELD = 'selected';
    const DATA_ITEM_KEY: keyof Document = 'documentId';
    const idGetter = getter(DATA_ITEM_KEY);
    const isMobile = window.innerWidth <= 768;
    const initialSort: Array<SortDescriptor> = [{ field: 'formatedDate', dir: 'desc' }];

    const { model } = useSelector<RootState, ImagingModelSlice>((state) => state.imaging);
    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);

    const gridLanguage = useGridLanguage(selectedLanguage);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const translate = useTranslation();
    const dispatch = useDispatch();

    const [group, setGroup] = useState<GroupedDocuments | null>(null);
    const { dateDropdownLabel } = useSelector<RootState, DateFilterSlice>((state) => state.dateFilter);
    const [dataState, setDataState] = useState<({ [SELECTED_FIELD]: boolean } & Document)[]>();

    const [selectedState, setSelectedState] = useState<{ [key: string]: boolean }>({});
    const [headerCheckbox, setHeaderCheckbox] = useState<boolean>(false);
    const [sort, setSort] = useState(initialSort);
    const [filter, setFilter] = useState<any>({});
    const [valueStartDate, setValueStartDate] = useState<Date | null>(new Date(1990, 0, 1));
    const [valueEndtDate, setValueEndtDate] = useState<Date | null>(new Date(2040, 0, 1));

    const [gridKey, setGridKey] = useState(0);

    const docTypeFiltersHashMap = country?.Code === 'us' ? [...DocumentsTypeFilterUS] : [...DocumentsTypeFilterCA];

    const checkboxHandler = (id: string) => {
        setSelectedState((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const GridCell = useCallback(
        (props: GridCellProps) => {
            return <FormattedGridCell onCheckboxClick={checkboxHandler} entryNo={entryNo} country={country?.Code!} {...props} />;
        },
        [entryNo]
    );

    const viewButtonClick = () => {
        const entryNo = Object.keys(group!)[0];
        const images = group![entryNo];

        const selectedDocuments = Object.keys(selectedState)
            .filter((key) => selectedState[key] === true)
            .map((item) => {
                const selectedImage = images.find((image) => image._pid === item) || {};

                return {
                    _metadataFormat: selectedImage._metadataFormat ?? '',
                    _fileS3Path: selectedImage._fileS3Path ?? '',
                    _imageDate: selectedImage._imageDate ?? '',
                    secondaryDescription: selectedImage.USDOCUMENTTYPE ?? selectedImage.LIDOCUMENTID,
                    version: selectedImage.LIVERSION ?? ''
                };
            });

        const dataForViewModal = {
            entry: entryNo,
            documents: selectedDocuments
        };

        setModalView(null);
        dispatch(addViewImagesData({ viewImages: [dataForViewModal], openedLocation: 'entryDocumentsGrid' }));
    };

    function findDate(type: any) {
        if (!dataState || dataState.length === 0) {
            return null;
        }

        let dateComparator = type === 'min' ? Infinity : -Infinity;
        let foundDate = null;

        for (let i = 0; i < dataState.length; i++) {
            const currentDate: any = new Date(dataState[i].formatedDate || 0);

            if (isNaN(currentDate)) {
                continue;
            }

            if (type === 'min' && currentDate < dateComparator) {
                dateComparator = currentDate;
                foundDate = currentDate;
            } else if (type === 'max' && currentDate > dateComparator) {
                dateComparator = currentDate;
                foundDate = currentDate;
            }
        }
        return foundDate;
    }

    useEffect(() => {
        const entryNoGroup = model.SearchResults.find((group) => group[entryNo]);
        if (entryNoGroup) {
            setGroup(entryNoGroup);
        }
    }, [entryNo, model.SearchResults]);

    useEffect(() => {
        if (group) {
            const newSelectedState: { [key: string]: boolean } = {};
            let filteredData = Object.values(group)[0];

            const dataForGrid = filteredData.map((item) => {
                const {
                    _pid: documentId,
                    _metadataFormat: metadataFormat,
                    _imageDate: imageDate,
                    _metadataFormat_raw: documentType,
                    USDOCUMENTTYPE,
                    LIDOCUMENTID,
                    _fileS3Path: path,
                    LIVERSION,
                    USCICONSOL,
                    _filename: documentName
                } = item;

                const secondaryDescription = USDOCUMENTTYPE ?? LIDOCUMENTID;
                const formatedDate = imageDate ? new Date(moment(imageDate).utc().startOf('day').format('MM/DD/YYYY')) : new Date(0);

                const currentObject: any = {
                    documentId,
                    metadataFormat,
                    imageDate,
                    documentType,
                    secondaryDescription,
                    path,
                    LIVERSION,
                    USCICONSOL,
                    documentName,
                    formatedDate
                };

                currentObject.docType = `${
                    translate(
                        docTypeFiltersHashMap.find((type) => type.id === currentObject.metadataFormat?.trim().toUpperCase())?.label
                    ) || ''
                }${
                    currentObject.secondaryDescription?.replace('-', '')?.toUpperCase() === currentObject.metadataFormat?.toUpperCase() ||
                    !currentObject.secondaryDescription?.replace('-', '')
                        ? ''
                        : ' - ' + (currentObject.secondaryDescription?.replace('-', '') || '')
                }${country?.Code === 'us' && currentObject.USCICONSOL ? ' - ' + currentObject.USCICONSOL : ''}`;
                return currentObject;
            });

            dataForGrid?.forEach((item) => {
                newSelectedState[item.documentId!] = false;
            });
            if (dataForGrid && dataForGrid.length > 0) {
                setSelectedState(newSelectedState);
                setDataState(dataForGrid.map((item) => ({ ...item, [SELECTED_FIELD]: false })));
            }
        }
    }, [group]);

    useEffect(() => {
        if (Object.keys(selectedState).filter((x) => selectedState[x] === false).length === 0) {
            setHeaderCheckbox(true);
        } else {
            setHeaderCheckbox(false);
        }

        let selectedIds = Object.keys(selectedState).filter((key) => selectedState[key] === true);
        if (selectedIds.length > 0 && dataState) {
            setDownloadModalData(dataState.filter((obj) => selectedIds.includes(obj.documentId)).map((obj) => obj.path));
        }
    }, [selectedState, setSelectedState]);

    useEffect(() => {
        return () => {
            setGroup(null);
        };
    }, []);

    useEffect(() => {
        dispatch(setCheckedDocuments({}));
        setFilter({});
        dispatch(setDateDropdownLabel('DateRangeAllTime_Label'));
    }, [entryNo]);

    const HeaderCell = useCallback(
        (props: GridHeaderCellProps) => {
            const headerCheckboxHandler = () => {
                if (Object.keys(selectedState).filter((x) => selectedState[x] === false).length === 0) {
                    setSelectedState((prevState) =>
                        Object.keys(prevState).reduce(
                            (acc, curr) => ({
                                ...acc,
                                [curr]: false
                            }),
                            {}
                        )
                    );
                    setHeaderCheckbox(false);
                } else {
                    setSelectedState((prevState) =>
                        Object.keys(prevState).reduce(
                            (acc, curr) => ({
                                ...acc,
                                [curr]: prevState[curr] ? true : !prevState[curr]
                            }),
                            {}
                        )
                    );
                    setHeaderCheckbox(true);
                }
            };

            return (
                <div>
                    <Checkbox value={headerCheckbox} onChange={headerCheckboxHandler} />
                </div>
            );
        },
        [headerCheckbox, selectedState]
    );

    return (
        <div key={gridKey}>
            {dataState && group && (
                <LocalizationProvider language={gridLanguage.language}>
                    <IntlProvider locale={gridLanguage.locale}>
                        <Grid
                            data={orderBy(
                                dataState
                                    .filter(
                                        (document) =>
                                            (Object.keys(filter).length > 0 ? filter[document.documentId] === true : true) &&
                                            calculateDateRange(dateDropdownLabel, document.imageDate)
                                    )
                                    .map((item) => ({
                                        ...item,
                                        [SELECTED_FIELD]: selectedState[idGetter(item)]
                                    })),
                                sort
                            )}
                            style={{ maxHeight: '60vh' }}
                            className={country?.Code === 'ca' ? 'documents-table entry-documents-table flex-fill' : ''}
                            total={dataState ? dataState.length : 0}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e: GridSortChangeEvent) => {
                                setSort(e.sort);
                                setGridKey(generateGridKey(gridKey));
                            }}
                        >
                            <Column field={SELECTED_FIELD} cell={GridCell} headerCell={HeaderCell} />
                            <Column
                                field='docType'
                                title={translate('DocumentType_Label')}
                                width={country?.Code === 'ca' ? 180 : 280}
                                sortable={true}
                                columnMenu={(props: any) => (
                                    <ColumnMenuCheckboxFilter
                                        {...props}
                                        documents={dataState}
                                        filter={filter}
                                        setFilter={setFilter}
                                        gridKey={gridKey}
                                        setGridKey={setGridKey}
                                    />
                                )}
                                cell={(props: GridCellProps) => {
                                    const { dataItem } = props;

                                    const docTypeIcon = <FontAwesomeIcon icon={faFile} className={'mr-2'} />;
                                    const x = `${
                                        translate(
                                            docTypeFiltersHashMap.find((type) => type.id === dataItem.metadataFormat?.trim().toUpperCase())
                                                ?.label
                                        ) || ''
                                    }${
                                        dataItem.secondaryDescription?.replace('-', '')?.toUpperCase() ===
                                            dataItem.metadataFormat?.toUpperCase() || !dataItem.secondaryDescription?.replace('-', '')
                                            ? ''
                                            : ' - ' + (dataItem.secondaryDescription?.replace('-', '') || '')
                                    }${country?.Code === 'us' && dataItem.USCICONSOL ? ' - ' + dataItem.USCICONSOL : ''}`;
                                    return (
                                        <td className={'text-nowrap text-primary'}>
                                            {docTypeIcon}{' '}
                                            <span
                                                style={{
                                                    textDecoration: 'underline',
                                                    textDecorationStyle: 'dotted',
                                                    textDecorationColor: 'blue'
                                                }}
                                            >
                                                {x.trim()}
                                            </span>
                                        </td>
                                    );
                                }}
                            />
                            {country?.Code === 'ca' && (
                                <Column field='Version' sortable={false} title={translate('Version_Label')} cell={GridCell} width={20} />
                            )}
                            <Column
                                headerCell={(props) => (
                                    <>
                                        <span onClick={props.onClick} className='k-cell-inner'>
                                            <span className='k-link'>
                                                <span className='k-column-title' style={{ display: 'inline-block' }}>
                                                    {props.title}
                                                </span>
                                                <span style={{ display: 'inline-block' }}>{props.children}</span>
                                            </span>
                                        </span>
                                        <GridColumnMenuWrapper {...props.columnMenuWrapperProps} />
                                    </>
                                )}
                                field='formatedDate'
                                title={translate('ImageDate_Label')}
                                width={country?.Code !== 'ca' ? '160' : ''}
                                format='{0:MM/dd/yyyy}'
                                sortable={true}
                                columnMenu={(props: any) => (
                                    <ColumnMenuDateFilter
                                        valueEndDate={valueEndtDate}
                                        valueStartDate={valueStartDate}
                                        setValueEndtDate={setValueEndtDate}
                                        setValueStartDate={setValueStartDate}
                                        {...props}
                                    />
                                )}
                            />
                            <Column
                                field='Download'
                                title=' '
                                sortable={false}
                                cell={GridCell}
                                width={35}
                                locked
                                {...(isMobile && { width: '20' })}
                            />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            )}

            {Object.entries(selectedState).filter(([key, value]) => value === true).length > 0 && (
                <DocumentsFooter
                    buttonArray={ButtonArray}
                    amountEntries={'1'}
                    amountSelected={Object.entries(selectedState).filter(([key, value]) => value === true).length + ''}
                    downloadButtonParam={'entryDocumentsGrid'}
                    downloadButtonClick={downloadButtonClick}
                    viewButtonClick={() => viewButtonClick()}
                    compareButtonClick={() => {
                        dispatch(setEllipsisEntryNo(entryNo));
                        setModalView(null);
                        setDocumentDetailsCompareView();
                    }}
                />
            )}
        </div>
    );
};

export default EntryDocumentsTable;
