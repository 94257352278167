import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { AUTHID, GTMID, PREVIEWID } from '../constants/mlp-constants';

export const initializeGTM = (user_id: number) => {
    if (GTMID.length > 0 && !window?.dataLayer?.length) {
        const tagManagerArgs: TagManagerArgs = {
            dataLayer: {
                user_id: user_id || 0
            },
            gtmId: GTMID,
            auth: AUTHID,
            preview: PREVIEWID
        };
        TagManager.initialize(tagManagerArgs);
    }
};
