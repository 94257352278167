import { faCalculator, faChalkboardTeacher, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { customsBrokerageCalculatorUrl, tradeNewsUrl, webinarsUrl } from '../constants/mlp-constants';
import { shipmentDataSourceConstants } from '../portal/common/constants/constants-portal';
import ClientChooser from '../portal/common/features/ClientChooser/ClientChooser';
import Spinner from '../portal/common/features/Spinner';
import useLocalStorageItem from '../portal/common/hooks/useLocalStorageItem';
import useTranslation from '../portal/common/hooks/useTranslation';
import { ClientSelectionSlice, HydrationSlice } from '../portal/common/models/ReduxSlices';
import { SaveShipmentDataSource } from '../portal/redux/actions/shipmentDataSource';
import CounterCard from './comon/components/cards/CounterCard';
import SpringboardCard from './comon/components/cards/SpringboardCard';
import SupportCard from './comon/components/cards/SupportCard';
import Col from './comon/components/main/Col';
import Container from './comon/components/main/Container';
import HeaderWithDropdown from './comon/components/main/HeaderWithDropdown';
import Row from './comon/components/main/Row';
import StageWrapper from './comon/components/main/StageWrapper';
import { ShipmentListSlice } from './comon/models/ReduxSlices';
import { OverviewDashboardSlice } from './comon/models/overviewDashboard';
import { GetShipmentList } from './redux/actions/shipmentList';
import { RootState } from './redux/store';
import ClearancesSnapshot from './screens/ClearancesSnapshot';

const OverviewDashboard = () => {
    const dispatch = useDispatch();
    const translate = useTranslation();
    const history = useHistory();
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { initialClientSelection, applyClicked } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const { totalInProgress, totalOnHold, totalCompleted, isLoading } = useSelector<RootState, OverviewDashboardSlice>(
        (state) => state.overviewDashboard
    );
    const { handleLocalStorageSetItem } = useLocalStorageItem('preApplyStatus');
    const { shipmentDataSource } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const [redirect, setRedirect] = useState(false);
    const { shipmentList, isLoading: isLoadingShipments } = useSelector<RootState, ShipmentListSlice>((state) => state.shipmentsWithTotals);

    useEffect(() => {
        //Get Data from the API
        const getData = () => {
            const params = {
                Status: null,
                Country: country?.Code.toUpperCase(),
                ClientCaptionNumbers: user?.ClientCaptionNumbers,
                PageSize: user?.PortalClearancesSnapshotSummaryLimit ?? 15,
                OrderBy: null,
                StartRow: 0,
                DateRange: { dateRangeSelected: 'LAST_30', customDateRange: [] },
                AdvancedSearchFilters: { Tags: [] },
                ClearancesCreatedOnDate: ''
            };
            dispatch(GetShipmentList(params));
        };

        if (user?.ClientCaptionNumbers && country?.Code && applyClicked !== false) {
            getData();
        }
    }, [user?.ClientCaptionNumbers, country?.Code, applyClicked, dispatch, user?.PortalClearancesSnapshotSummaryLimit]);

    useEffect(() => {
        if (shipmentDataSource === shipmentDataSourceConstants.us && redirect) {
            history.push('/Clearances');
        }
    }, [shipmentDataSource, redirect, history]);

    const handleViewClick = (status: string) => {
        dispatch(SaveShipmentDataSource({ PreferenceValue: shipmentDataSourceConstants.us, Country: 'ca' }));
        handleLocalStorageSetItem(status);
        setRedirect(true);
    };

    const handleNewTabLink = (url: string) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        <StageWrapper marginBottom>
            <Container fluid>
                <Row>
                    <Col>
                        <HeaderWithDropdown>
                            <ClientChooser />
                        </HeaderWithDropdown>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* <AlertWithImageAndCta
                             className='mb-4'
                             id='alert-with-image'
                             alertType={AlertType.Info}
                             title='Welcome to Portal!'
                             message='Download your welcome package to get started'
                             primaryAction={<AlertPrimaryActionButton alertType={AlertType.Info} />}
                             handleDismiss={() => handleAlertDismiss()}
                             imagePath='/assets/images/icons/suitcase.svg'
                         /> */}
                    </Col>
                </Row>
                <Row>
                    <Col className='col-12 col-md-4 mb-4'>
                        <CounterCard
                            title={translate('Clearances_Label')}
                            status={translate('ViewLast30OnHold_Label')}
                            count={totalOnHold}
                            dangerText
                            spinner={<Spinner />}
                            loading={isLoading || totalOnHold === undefined ? true : false}
                            button={{
                                label: translate('ViewOnHold_Label'),
                                onClick: () => handleViewClick('ON_HOLD')
                            }}
                        />
                    </Col>
                    <Col className='col-12 col-md-4 mb-4'>
                        <CounterCard
                            title={translate('Clearances_Label')}
                            status={translate('ViewLast30Completed_Label')}
                            count={totalCompleted}
                            spinner={<Spinner />}
                            loading={isLoading || totalCompleted === undefined ? true : false}
                            button={{
                                label: translate('ViewCompleted_Label'),
                                onClick: () => handleViewClick('COMPLETED')
                            }}
                        />
                    </Col>
                    <Col className='col-12 col-md-4 mb-4'>
                        <CounterCard
                            title={translate('Clearances_Label')}
                            status={translate('ViewLast30InProgress_Label')}
                            count={totalInProgress}
                            spinner={<Spinner />}
                            loading={isLoading || totalInProgress === undefined ? true : false}
                            button={{
                                label: translate('ViewInProgress_Label'),
                                onClick: () => handleViewClick('IN_PROGRESS')
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='grid-container dashboard-grid-layout'>
                            <ClearancesSnapshot
                                clearances={shipmentList}
                                loading={isLoadingShipments}
                                translate={translate}
                                country={country?.Code ?? 'us'}
                            />
                            <div className='dashboard-card-2'>
                                <SpringboardCard
                                    icon={faNewspaper}
                                    title={translate('TradeNews_Label')}
                                    onClick={() => handleNewTabLink(tradeNewsUrl)}
                                />
                            </div>
                            <div className='dashboard-card-3'>
                                <SpringboardCard
                                    icon={faCalculator}
                                    title={translate('BrokerageCalculator_Label')}
                                    onClick={() => handleNewTabLink(customsBrokerageCalculatorUrl)}
                                />
                            </div>
                            <div className='dashboard-card-4'>
                                <SpringboardCard
                                    icon={faChalkboardTeacher}
                                    title={translate('Webinars_Label')}
                                    onClick={() => handleNewTabLink(webinarsUrl)}
                                />
                            </div>
                            <div className='dashboard-card-5'>
                                <SupportCard country={country?.Code} translate={translate} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StageWrapper>
    );
};

export default OverviewDashboard;
