import { faCaretDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../../../../portal/common/features/Spinner';
import { LanguageSlice } from '../../../../portal/common/models/ReduxSlices';
import { RootState } from '../../../../portal/redux/store';
import { parseDateTimeET } from '../../functions/parser';
import classes from './ExpandableAlertCard.module.css';

interface ExpandableAlertCardProps {
    messageKey: string;
    eventTypeKey: string;
    heldOn: string;
    directory: string;
}

export default function ExpandableAlertCard({ messageKey, eventTypeKey, heldOn, directory }: ExpandableAlertCardProps) {
    const { languageSelected } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const languageCode = languageSelected?.code;

    const [{ isFetching, data, error }, setRequest] = useState<{ isFetching: boolean; data: null | string; error: null | string }>({
        isFetching: false,
        data: null,
        error: null
    });

    useEffect(() => {
        if (!languageCode) return;

        (async function () {
            try {
                setRequest({ isFetching: true, data: null, error: null });
                const response = await fetch(`/data/${directory}/${languageCode}/${messageKey}.html`);
                const html = await response.text();

                if (html.includes('<!DOCTYPE html>')) {
                    throw new Error('File not found');
                }

                const sanitizedHTML = DOMPurify.sanitize(html);
                setRequest((prevState) => ({ ...prevState, data: sanitizedHTML }));
            } catch (error) {
                setRequest((prevState) => ({ ...prevState, error: String(error) }));
            } finally {
                setRequest((prevState) => ({ ...prevState, isFetching: false }));
            }
        })();
    }, [messageKey, languageCode, directory]);

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded((prevState) => !prevState);
    };

    if (isFetching) {
        return (
            <div className='d-flex justify-content-center align-items-center'>
                <Spinner />
            </div>
        );
    }

    return (
        <div className={`alert alert-danger my-2 ${classes.container}`}>
            <div className='row'>
                <div className='col-sm-7 col-8 d-flex'>
                    <FontAwesomeIcon icon={faInfoCircle} className='mr-3 mt-1' />
                    <div>
                        <p className='mb-0'>
                            <strong className='mr-1'>Shipment on hold by Livingston:</strong>
                            {eventTypeKey}
                        </p>
                        <p className='mb-sm-0'>Expand for additional details and directions.</p>
                    </div>
                </div>
                <p className='col-sm-3 col-4 d-flex align-items-md-center mb-0'>{parseDateTimeET(heldOn)}</p>
                <div className='d-sm-none d-block w-100' />
                <div className='col col-sm-2 d-flex justify-content-sm-end justify-content-center align-items-md-center align-items-top'>
                    <button
                        className={`d-flex align-items-center text-link font-weight-normal p-0 m-0 ${classes['btn-expand']}`}
                        onClick={toggleExpand}
                    >
                        <span className='mr-2'>View Livingston hold info</span>
                        <FontAwesomeIcon icon={faCaretDown} className={isExpanded ? classes.expanded : undefined} />
                    </button>
                </div>
            </div>
            {isExpanded && error && <p className='text-lii-text mt-4 mb-0'></p>}
            {isExpanded && data && <div className='text-lii-text mt-4 mb-0' dangerouslySetInnerHTML={{ __html: data }} />}
        </div>
    );
}
