import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DocTypeSlice } from '../../common/models/ReduxSlices';

const initialState: DocTypeSlice = {
    checkedDocuments: {}
};
const dateFilterSlice = createSlice({
    name: 'docTypeSlice',
    initialState,
    reducers: {
        setCheckedDocuments(state, action: PayloadAction<Record<string, boolean>>) {
            state.checkedDocuments = action.payload;
        }
    }
});
export const { setCheckedDocuments } = dateFilterSlice.actions;

export default dateFilterSlice.reducer;
