import $ from 'jquery';
import axios from '../../../axios-mlp';
import { initializeGTM } from '../../../utils/gtm';
import * as actionTypes from './dashboardActionTypes';

export const setDashboard = (viewModel) => {
    return {
        type: actionTypes.SET_DASHBOARD,
        model: viewModel
    };
};

export const initDashboard = () => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('/Dashboard/Index')
            .then((response) => {
                // Initialize GTM for MLP
                initializeGTM(response?.data?.User?.UserId);

                dispatch(setDashboard(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 302) {
                    window.location.href = window.location.protocol + '//' + document.location.host + error.response.data;
                } else if (error.response && error.response.status !== 401) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const updateWidgetSortOrder = (data) => {
    return {
        type: actionTypes.UPDATE_WIDGET_SORT_ORDER,
        data: data
    };
};

export const updateDashboardClientService = (data) => {
    return {
        type: actionTypes.UPDATE_CLIENT_SERVICE,
        data: data
    };
};

export const updateDashboardWidgets = (data) => {
    return {
        type: actionTypes.UPDATE_WIDGETS,
        data: data
    };
};

export const resetStateDashboard = () => {
    return {
        type: actionTypes.RESET_STATE_DASHBOARD
    };
};
