import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    GridColumn as Column,
    Grid,
    GridCellProps,
    GridDataStateChangeEvent,
    GridPageChangeEvent,
    GridSortChangeEvent
} from '@progress/kendo-react-grid';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import ErrorPage from '../../../../portal/common/features/ErrorPage';
import GridLoadingOverlay from '../../../../portal/common/features/Grid/GridLoadingOverlay';
import UnsubscribeFooter from '../../../../portal/common/features/Grid/UnsubscribeFooter';
import Spinner from '../../../../portal/common/features/Spinner';
import useGridColumnMinWidth from '../../../../portal/common/hooks/useGridColumnMinWidth';
import useGridLanguage from '../../../../portal/common/hooks/useGridLanguage';
import useGridPreferences from '../../../../portal/common/hooks/useGridPreferences';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { ColumnSettings } from '../../../../portal/common/models/GridPreferences';
import { ClientSelectionSlice, GridPreferencesSlice, HydrationSlice, LanguageSlice } from '../../../../portal/common/models/ReduxSlices';
//import { SaveUserClearancesGridSetting } from '../../../redux/actions/clearancesGridPreferences';
import { ShipmentListItem } from '../../../../portal/common/models/ShipmentList';
import {
    clearancesGridSettingsConstants,
    DestinationFilter,
    MilestoneFilter,
    MOTFilter
} from '../../../common/constants/constants-clearances';
import { configFilters } from '../../../common/functions/documentModelFunctions';
import useDisableColumnsDrag from '../../../common/hooks/useDisableColumnsDrag';
import {
    DateRangeSlice,
    DocumentsSlice,
    SearchSlice,
    ShipmentGridSettingsSlice,
    ShipmentListSlice,
    ShipmentStatusSlice
} from '../../../common/models/ReduxSlices';
import { DownloadDocuments, ExportHeaderAndDetail } from '../../../redux/actions/clearanceDocuments';
import { SaveUserClearancesGridSetting } from '../../../redux/actions/clearancesGridPreferences';
import { GetDocumentsIndex, GetShipmentList } from '../../../redux/actions/shipmentList';
import { updateColumnOrderGrid } from '../../../redux/reducers/clearancesGridPreferencesSlice';
import {
    setColumnFilters,
    setDataState,
    setPageSelected,
    setPageSize,
    setSortFields
} from '../../../redux/reducers/shipmentGridSettingsSlice';
import {
    addShipmentSelection,
    removeShipmentSelection,
    resetCheckedEntries,
    updateAllShipmentSelection
} from '../../../redux/reducers/shipmentListSlice';
import { resetStatus } from '../../../redux/reducers/shipmentStatusSlice';
import { RootState } from '../../../redux/store';
import FormattedGridCell from './FormattedGridCell';
import NoSearchResults from './NoSearchResults';

const ShipmentsGrid = () => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { statusSelected: selectedStatus } = useSelector<RootState, ShipmentStatusSlice>((state) => state.shipmentStatus);
    const { dateRangeSelected: selectedDateRange, customDateRange } = useSelector<RootState, DateRangeSlice>((state) => state.dateRange);
    const {
        shipmentList,
        isLoading: clearanceListIsLoading,
        error,
        allSelectedEntries,
        documentsIndex,
        docsIndexIsLoading
    } = useSelector<RootState, ShipmentListSlice>((state) => state.shipmentsWithTotals);
    const { initialClientSelection, isLoadingGet: clientSelectionIsLoading } = useSelector<RootState, ClientSelectionSlice>(
        (state) => state.clientSelection
    );
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    // const [clientSelections, setClientSelections] = useState();
    const { isLoadingDownload } = useSelector<RootState, DocumentsSlice>((state) => state.clearanceDocuments);
    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const gridSettings = useSelector<RootState, ShipmentGridSettingsSlice>((state) => state.shipmentGridSettings);
    const searchFilter = useSelector<RootState, SearchSlice>((state) => state.searchFilter);
    const { applyClicked } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const { allColumns, visibleColumns, columnsWidths, areColumnsLoading } = useSelector<RootState, GridPreferencesSlice>(
        (state) => state.clearancesGridPreferences
    );
    const { setWidth } = useGridColumnMinWidth({ columns: visibleColumns });
    const dispatch = useDispatch();
    const translate = useTranslation();

    const gridLanguage = useGridLanguage(selectedLanguage);
    const SELECTED_FIELD = 'selected';

    const gridRef = useRef(null);
    const [headerCheckbox, setHeaderCheckbox] = useState(false);

    const tableHeaders = document.getElementsByTagName('th');
    const elements = [tableHeaders[0] as HTMLElement, tableHeaders[tableHeaders.length - 1]];
    const { disableDrag } = useDisableColumnsDrag({ isLoading: clearanceListIsLoading, elements });

    const { columns, handleColumnReorder } = useGridPreferences({
        allColumns,
        visibleColumns,
        columnsWidths,
        onColumnReorder: (value) => dispatch(updateColumnOrderGrid(value)),
        saveColumnReorder: (value) => saveGridSettings(value)
    });

    const selectedCountryCodeInitial = initialClientSelection?.Countries.find((c) => c.IsSelected)?.Code;

    const saveGridSettings = (value: ColumnSettings[]) => {
        dispatch(
            SaveUserClearancesGridSetting({
                GridColumns: value.filter((item: ColumnSettings) => {
                    if (item.Code !== 'selected' && item.Code !== 'Actions') {
                        return item;
                    }
                    return null;
                }),
                area: country?.Code === 'us' ? clearancesGridSettingsConstants['us'] : clearancesGridSettingsConstants['ca']
            })
        );
    };

    const { totalSelected } = useSelector<RootState, ShipmentListSlice>((state) => state.shipmentsWithTotals);

    useEffect(() => {
        //@ts-ignore
        setHeaderCheckbox(shipmentList.every((item) => allSelectedEntries?.includes(item.EntryNumber)));
    }, [shipmentList, allSelectedEntries, headerCheckbox]);

    // Data Load

    const handleHeaderCheckbox = useCallback(() => {
        setHeaderCheckbox(!headerCheckbox);
        const selection = shipmentList.map((item) => {
            item = { ...item, selected: !headerCheckbox };

            return item;
        });
        dispatch(
            updateAllShipmentSelection({
                selectedEntries: selection,
                headerChecked: headerCheckbox,
                maxDownloadItems: user?.PortalClearancesMaxItemDownloadNumber
            })
        );
    }, [dispatch, headerCheckbox, shipmentList, user]);

    const handleCheckbox = useCallback(
        (event: CheckboxChangeEvent) => {
            if (!event.target.name) return;
            if (event.target.value) {
                dispatch(addShipmentSelection(event.target.name));
            } else {
                dispatch(removeShipmentSelection(event.target.name));
            }
        },
        [dispatch]
    );

    // Header with drag icon
    const customHeader = useCallback(
        (props: any) => {
            return props.field === 'selected' ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        value={headerCheckbox}
                        disabled={
                            !headerCheckbox &&
                            !!allSelectedEntries &&
                            !!user &&
                            !!user.PortalClearancesMaxItemDownloadNumber &&
                            allSelectedEntries.length >= user.PortalClearancesMaxItemDownloadNumber
                        }
                        onChange={handleHeaderCheckbox}
                    />
                </div>
            ) : (
                <div className='d-flex justify-content-between' onClick={props.onClick}>
                    <div className='k-column-title'>{props.title}</div>
                    {props.children}
                    <div className='text-lii-text-light'>
                        <FontAwesomeIcon icon={faGripVertical} />
                    </div>
                </div>
            );
        },
        [headerCheckbox, handleHeaderCheckbox, allSelectedEntries, user]
    );

    // Show red color for the row which has the shipment "On hold"
    const rowRender = (trElement: any, props: any) => {
        const trProps = {
            className: props.dataItem?.ClearanceStatus === 'ON_HOLD' ? 'red-bg-row' : undefined
        };

        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    };

    // Click on row for Shipment details
    // Comented out as this functionality is now achieved by having onClick listener on all cells in FormatedGridCell component

    // const clikRowDetails = (event: GridRowClickEvent) => {
    //     const customsInfoID = event.dataItem.CustomsInfoID;
    //     history.push(`/Clearances/${customsInfoID}`);
    //     localStorage.setItem('activeTab', TabNavigationIds.Clearances[0]);
    // };

    // Sort Change
    const sortChange = (event: GridSortChangeEvent) => {
        dispatch(setSortFields(event.sort));
    };

    // Column Filter Change
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        var filtersList: any = {};
        if (event.dataState.filter) {
            event.dataState.filter.filters.map(function (field: any) {
                return field.filters.map(function (obj: any) {
                    if (obj) {
                        switch (obj.field) {
                            case 'ModeOfTransport':
                                var result: any = MOTFilter.filter((o) => {
                                    return translate(o.label) === obj.value;
                                });

                                obj.field in filtersList
                                    ? filtersList[obj.field as keyof typeof filtersList].push(result[0].id)
                                    : (filtersList[obj.field] = [result[0].id]);

                                break;
                            case 'Milestone':
                                result = MilestoneFilter.filter((o) => {
                                    if (country?.Code.toLowerCase() === 'all')
                                        return o.destination + ' - ' + translate(o.label) === obj.value;
                                    else return o.destination + ' - ' + translate(o.label) === country?.Code + ' - ' + obj.value;
                                });

                                obj.field in filtersList
                                    ? filtersList[obj.field].push(result[0].destination + '-' + result[0].value)
                                    : (filtersList[obj.field] = [result[0].destination + '-' + result[0].value]);

                                break;

                            case 'Destination':
                                result = DestinationFilter.filter((o) => {
                                    return translate(o.label) === obj.value;
                                });

                                obj.field in filtersList
                                    ? filtersList[obj.field].push(result[0].value)
                                    : (filtersList[obj.field] = [result[0].value]);

                                break;

                            case 'BillOfLading.MasterBill':
                                filtersList['BillOfLading'] = obj.value;

                                break;

                            case 'ModifiedOn':
                                var d = new Date(obj.value);
                                obj.field in filtersList
                                    ? filtersList[obj.field].push({
                                          type: obj.operator === 'gte' ? 'startdate' : 'enddate',
                                          date: d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear()
                                      })
                                    : (filtersList[obj.field] = [
                                          {
                                              type: obj.operator === 'gte' ? 'startdate' : 'enddate',
                                              date: d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear()
                                          }
                                      ]);

                                break;

                            default:
                                filtersList[obj.field] = obj.value;
                                break;
                        }
                    }
                    return obj;
                });
            });
        }
        dispatch(setColumnFilters(filtersList));
        dispatch(setDataState(event.dataState));
        dispatch(resetStatus());
    };
    // useEffect(() => {
    //     if (clientSelection) {
    //         const client = clientSelection.Countries.filter((item) => item.IsSelected === true);
    //         //@ts-ignore
    //         setClientSelections(client);
    //     }
    //     //@ts-ignore
    // }, [dispatch, clientSelection, initialClientSelection]);

    // Pagination Section Change
    const pageChange = (event: GridPageChangeEvent) => {
        setHeaderCheckbox(!shipmentList.every((item) => item.selected));
        dispatch(setPageSelected(event.page.skip / gridSettings.pageSize + 1));
        dispatch(setPageSize(event.page.take));
    };

    const handleDownloadDocuments = () => {
        if (allSelectedEntries) {
            if (selectedCountryCodeInitial === 'us') {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'Doc_DNLD_Grid_CLR_CPEvt',
                        location: 'Clearances'
                    }
                });
            }

            dispatch(
                DownloadDocuments({
                    entryNumbers: { allEntries: allSelectedEntries }
                })
            );
        }
    };

    const handleDownloadDetails = () => {
        if (allSelectedEntries && documentsIndex) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'HL_DNLD_Grid_CLR_CPEvt',
                    location: 'Clearances'
                }
            });
            const shipments = allSelectedEntries
                .map((entryNo) => shipmentList.find((sh) => sh.EntryNumber === entryNo))
                .filter(Boolean) as Partial<ShipmentListItem>[];

            dispatch(
                ExportHeaderAndDetail({
                    shipments: shipments,
                    filters: configFilters(documentsIndex, allSelectedEntries)!,
                    imageGroupings: documentsIndex?.ImageGroupings
                })
            );
        }
    };

    useEffect(() => {
        dispatch(resetCheckedEntries());
    }, [
        country?.Code,
        selectedStatus,
        selectedDateRange.id,
        customDateRange,
        searchFilter,
        user?.PortalClearancesCreatedOnMinDate,
        applyClicked,
        dispatch
    ]);

    useEffect(() => {
        //Get Data from the API
        const getData = () => {
            const params = {
                Status: null,
                Country: country?.Code.toUpperCase(),
                ClientCaptionNumbers: user?.ClientCaptionNumbers,
                PageSize: gridSettings.pageSize,
                OrderBy: gridSettings.columnSortFields,
                StartRow: (gridSettings.pageSelected - 1) * gridSettings.pageSize + 1, // startRow
                ColumnFilters: gridSettings?.columnFilters, // {field: value, field: value, ...}
                DateRange: { dateRangeSelected: selectedDateRange.id, customDateRange: customDateRange },
                AdvancedSearchFilters: {
                    Tags: searchFilter.tags
                },
                ClearancesCreatedOnDate: user?.PortalClearancesCreatedOnMinDate ?? ''
            };
            dispatch(GetShipmentList(params));
        };

        if (user?.ClientCaptionNumbers && country?.Code && applyClicked !== false) {
            getData();
        }
    }, [
        country?.Code,
        user?.ClientCaptionNumbers,
        selectedStatus,
        selectedDateRange.id,
        customDateRange,
        gridSettings,
        searchFilter,
        user?.PortalClearancesCreatedOnMinDate,
        applyClicked,
        dispatch
    ]);

    useEffect(() => {
        if (!documentsIndex && country?.Code && country?.Code !== 'ca') dispatch(GetDocumentsIndex());
    }, [dispatch, country, documentsIndex]);

    // Grid Components
    const formattedCell = (props: GridCellProps) => (
        <FormattedGridCell {...props} selectedEntries={allSelectedEntries} checkboxChange={handleCheckbox} />
    );

    const calculateColumnWidth = (columnCode: string, columnWidth: number) => {
        const width = setWidth(columnsWidths?.find((item) => item.Code === columnCode)?.Width);
        return columnWidth > width ? columnWidth : width;
    };

    return (
        <>
            {shipmentList.length > 0 && !error && columns && (
                <LocalizationProvider language={gridLanguage.language}>
                    <IntlProvider locale={gridLanguage.locale}>
                        <div className='d-flex position-relative flex-fill'>
                            <Grid
                                ref={gridRef}
                                className='clearance-table flex-fill'
                                style={{ zIndex: 0 }}
                                data={shipmentList}
                                {...gridSettings.dataState}
                                total={totalSelected}
                                skip={(gridSettings.pageSelected - 1) * gridSettings.pageSize}
                                take={gridSettings.pageSize}
                                sort={gridSettings.columnSortFields}
                                pageable={totalSelected <= 10 ? false : gridSettings.pageable}
                                onPageChange={pageChange}
                                onSortChange={sortChange}
                                onDataStateChange={dataStateChange}
                                rowRender={rowRender}
                                sortable={true}
                                reorderable={!areColumnsLoading && !disableDrag ? true : false}
                                onColumnReorder={handleColumnReorder}
                                selectedField={SELECTED_FIELD}
                            >
                                <Column field={SELECTED_FIELD} cell={formattedCell} headerCell={customHeader} width='50px' orderIndex={0} />
                                {columns?.map((column) => (
                                    <Column
                                        key={column.Code}
                                        field={column.Code}
                                        title={translate(column.HeaderLabel)}
                                        headerCell={customHeader}
                                        sortable={column.IsSortable}
                                        cell={formattedCell}
                                        width={column.IsVisible ? calculateColumnWidth(column.Code, column.Width) : 0}
                                        orderIndex={column.Order}
                                    />
                                ))}
                                <Column
                                    field='Actions'
                                    title={' '}
                                    headerCell={undefined}
                                    cell={formattedCell}
                                    sortable={false}
                                    resizable={false}
                                    locked
                                    width='80%'
                                    orderIndex={columns.length}
                                />
                            </Grid>
                            {(clearanceListIsLoading || clientSelectionIsLoading || docsIndexIsLoading) && shipmentList.length > 0 && (
                                <GridLoadingOverlay />
                            )}
                        </div>
                        {allSelectedEntries && allSelectedEntries?.length > 0 && !clearanceListIsLoading && (
                            <UnsubscribeFooter
                                amountLabel={translate('ItemsSelected_Label', allSelectedEntries?.length)}
                                loadingDropdownButton={isLoadingDownload}
                                loadingSecondaryButton={isLoadingDownload}
                                selectedSubscribers={allSelectedEntries?.length}
                                // Button if CA selected
                                secondaryButtonLabel={selectedCountryCodeInitial === 'ca' ? translate('DownloadDocumentsButton_Label') : ''}
                                onSecondary={handleDownloadDocuments}
                                download={true}
                                // Dropdown if US selected
                                dropdownButtonLabel={selectedCountryCodeInitial === 'us' ? translate('DownloadButton_Label') : ''}
                                dropdownOptions={
                                    selectedCountryCodeInitial === 'us' && [
                                        {
                                            text: translate('HeaderAndLineInfo_Label'),
                                            onClick: handleDownloadDetails
                                        },
                                        {
                                            label: 'Documents_Label',
                                            onClick: handleDownloadDocuments
                                        }
                                    ]
                                }
                            />
                        )}
                    </IntlProvider>
                </LocalizationProvider>
            )}

            {/* No search results / no shipments */}
            {!clearanceListIsLoading && !clientSelectionIsLoading && !docsIndexIsLoading && shipmentList.length === 0 && (
                <div className='container-fluid d-flex flex-column justify-content-center align-items-center flex-fill mb-5'>
                    <NoSearchResults />
                </div>
            )}
            {/* Initial loading of the grid */}
            {(clearanceListIsLoading || clientSelectionIsLoading) && shipmentList.length === 0 && (
                <div className='container-fluid d-flex flex-column justify-content-center align-items-center flex-fill mb-5'>
                    <Spinner />
                </div>
            )}
            {/* Loading when grid has data */}

            {/* Status: rejected */}
            {error && <ErrorPage errorCode={error} withTranslations />}
        </>
    );
};

export default ShipmentsGrid;
