import React from 'react';
import Spinner from '../Spinner';
import classes from './GridLoadingOverlay.module.css';

interface GridLoadingOverlayProps {
    isSpinnerVisible?: boolean;
    onClick?: () => void;
}

const GridLoadingOverlay: React.FC<GridLoadingOverlayProps> = ({ isSpinnerVisible = true, onClick }: GridLoadingOverlayProps) => {
    return (
        <div className={classes.container} onClick={onClick}>
            {isSpinnerVisible && <Spinner />}
        </div>
    );
};

export default GridLoadingOverlay;
