import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../portal/axios-portal';
import { API_CLEARANCES_PATH } from '../../../portal/common/constants/constants-portal';
import { Subscription } from '../../../portal/common/models/ClearanceSubscriptions';
import { TeamMembersAndContacts } from '../../../portal/common/models/ComboBoxSubscribers';
import { Notifications } from '../../../portal/common/models/ResponseData';
import { ShipmentDetailsData, SubscriptionUpdateData, UnsubscribeData } from '../../common/models/ResponseData';
import {
    GET_CLEARANCE_SUBSCRIBERS,
    GET_NOTIFICATIONS_SETTINGS,
    GET_TEAM_MEMBERS_AND_CONTACTS,
    SUBSCRIBE_USERS,
    UNSUBSCRIBE_USERS,
    UPDATE_CLEARANCE_SUBSCRIBERS,
    UPDATE_NOTIFICATIONS_SETTINGS
} from './actionTypes';

export const GetClearanceSubscribers = createAsyncThunk(
    GET_CLEARANCE_SUBSCRIBERS,
    async (payload: { customsInfoId: number; clientCaptionNumber: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post<{ SubscriptionsPerClientNumbers: Subscription[] }>(
                `${API_CLEARANCES_PATH}/clearance/notifications/${payload.customsInfoId}/clearance-subscribers`,
                {
                    ClientCaptionNumbers: [payload.clientCaptionNumber],
                    Email: '',
                    Phone: ''
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const UpdateSubscription = createAsyncThunk(
    UPDATE_CLEARANCE_SUBSCRIBERS,
    async (payload: { customsInfoId: number; requestModel: Subscription; milestones: null | Notifications[] }, { rejectWithValue }) => {
        try {
            const response = await axios.post<SubscriptionUpdateData>(
                `${API_CLEARANCES_PATH}/clearance/${payload.customsInfoId}/update-milestones-for-clearance-subscriber`,
                {
                    SubscriptionId: payload.requestModel.SubscriptionId,
                    CustomerNumber: payload.requestModel.CustomerNumber,
                    MilestoneNotifications: payload.milestones,
                    FullName: payload.requestModel.FullName,
                    Language: payload.requestModel.Language,
                    Email: payload.requestModel.Email,
                    Phone: payload.requestModel.Phone,
                    RoleName: payload.requestModel.RoleName
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const UnsubscribeUsers = createAsyncThunk(UNSUBSCRIBE_USERS, async (payload: string[], { rejectWithValue }) => {
    try {
        const response = await axios.put<UnsubscribeData>(`${API_CLEARANCES_PATH}/clearance/unsubscribe-subscriptions`, {
            SubscriptionIds: payload
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const SubscribeUsers = createAsyncThunk(
    SUBSCRIBE_USERS,
    async (
        payload: {
            name: string;
            userId: string;
            customerNumbers: string[];
            customsInfoId: number;
            subscribers: Subscription[];
            notificationsUS: Notifications[];
            notificationsCA: Notifications[];
            entryTransactionNumber: number | string;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<SubscriptionUpdateData>(
                `${API_CLEARANCES_PATH}/clearance/${payload.customsInfoId}/create-clearance-subscribers`,
                {
                    Name: payload.name,
                    CustomerNumbers: payload.customerNumbers,
                    UserId: payload.userId,
                    Subscribers: payload.subscribers,
                    MilestoneNotifications_US: payload.notificationsUS,
                    MilestoneNotifications_CA: payload.notificationsCA,
                    EntryTransactionNumber: payload.entryTransactionNumber
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const GetNotificationsSettings = createAsyncThunk(
    GET_NOTIFICATIONS_SETTINGS,
    async (
        payload: {
            customsInfoId: number;
            clientCaptionNumber: string;
            profileEmail: string | undefined;
            profileMobileNumber: string | undefined;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<ShipmentDetailsData['Subscription']>(
                `${API_CLEARANCES_PATH}/clearance/notifications/${payload.customsInfoId}`,
                {
                    ClientCaptionNumbers: [payload.clientCaptionNumber],
                    ProfileEmail: payload.profileEmail,
                    ProfileMobileNumber: payload.profileMobileNumber
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const UpdateNotificationSettings = createAsyncThunk(
    UPDATE_NOTIFICATIONS_SETTINGS,
    async (
        payload: {
            customsInfoId: number;
            clientCaptionNumber: string;
            notificationSettings: ShipmentDetailsData['Subscription'];
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<SubscriptionUpdateData>(
                `${API_CLEARANCES_PATH}/clearance/update-notifications/${payload.customsInfoId}`,
                {
                    ...payload.notificationSettings,
                    ClientCaptionNumbers: [payload.clientCaptionNumber],
                    CustomsInfoId: payload.customsInfoId,
                    SubscriptionId:
                        payload.notificationSettings?.SubscriptionIds && payload.notificationSettings.SubscriptionIds.length
                            ? payload.notificationSettings.SubscriptionIds[0]
                            : null
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const GetTeamMembersAndContacts = createAsyncThunk(
    GET_TEAM_MEMBERS_AND_CONTACTS,
    async (payload: string | undefined, { rejectWithValue }) => {
        const url = payload
            ? `${API_CLEARANCES_PATH}/clearance/get-team-members-externalcontacts-by-customer-number/${payload}`
            : `${API_CLEARANCES_PATH}/clearance/get-team-members-externalcontacts`;
        try {
            const response = await axios.get<TeamMembersAndContacts>(url);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.status || error.message);
        }
    }
);
