import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../portal/common/features/Button';
import useTranslation from '../../../../portal/common/hooks/useTranslation';

interface ActionButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({ onClick, disabled }) => {
    const translate = useTranslation();

    return (
        <Button onClick={onClick} variant='danger-ghost' size='small' disabled={disabled || false}>
            <FontAwesomeIcon icon={faTimesCircle} className='mr-2' />
            {translate('Remove_Label')}
        </Button>
    );
};

export default ActionButton;
