import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterDropdown from '../../../../portal/common/features/FilterDropdown';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { ClientSelectionSlice } from '../../../../portal/common/models/ReduxSlices';
import { MilestoneFilter, MOTFilter, MOTMap, StatusFilter } from '../../../common/constants/constants-clearances';
import { ShipmentGridSettingsSlice, ShipmentListSlice, ShipmentStatusSlice } from '../../../common/models/ReduxSlices';
import {
    setColumnFilters,
    setMilestoneSelectedIndexes,
    setMOTSelectedIndexes,
    setPageSelected
} from '../../../redux/reducers/shipmentGridSettingsSlice';
import { updateStatusesAmounts } from '../../../redux/reducers/shipmentListSlice';
import { setStatusSelected } from '../../../redux/reducers/shipmentStatusSlice';
import { RootState } from '../../../redux/store';

const DropdownFilters = () => {
    const dispatch = useDispatch();
    const translate = useTranslation();
    const { selectedIndexes } = useSelector<RootState, ShipmentStatusSlice>((state) => state.shipmentStatus);
    const { totalInProgress, totalCompleted, totalOnHold } = useSelector<RootState, ShipmentListSlice>(
        (state) => state.shipmentsWithTotals
    );
    const { MOTSelectedIndexes, MilestoneSelectedIndexes, columnFilters } = useSelector<RootState, ShipmentGridSettingsSlice>(
        (state) => state.shipmentGridSettings
    );
    const shipmentStatuses = [...StatusFilter];

    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const countryCode = initialClientSelection?.Countries.find((item) => item.IsSelected)?.Code.toUpperCase();

    const milestones = MilestoneFilter.filter((item) => countryCode === item.destination);

    const handleApply = (id: string, selectedArray: number[]) => {
        switch (id) {
            case 'Statuses':
                dispatch(
                    setColumnFilters({
                        ...columnFilters,
                        ClearanceStatus: selectedArray.map((item) => StatusFilter[item].label.replace('_Label', ''))
                    })
                );
                dispatch(setStatusSelected(selectedArray));
                break;
            case 'MOT':
                const selectedMOT = selectedArray.map((i) => {
                    return Object.keys(MOTMap)[i];
                });
                dispatch(setColumnFilters({ ...columnFilters, ModeOfTransport: selectedMOT }));
                dispatch(setMOTSelectedIndexes(selectedArray));
                break;
            case 'Milestones':
                const selectedMilestones = selectedArray.map((i) => {
                    return `${countryCode}-${milestones[i].value}`;
                });
                dispatch(setColumnFilters({ ...columnFilters, Milestone: selectedMilestones }));
                dispatch(setMilestoneSelectedIndexes(selectedArray));
                break;
        }
        dispatch(setPageSelected(1));
    };

    // Update the amounts for all statuses
    useEffect(() => {
        const statuses = [totalInProgress, totalOnHold, totalCompleted];
        let selectedStatusesTotal = 0;
        selectedIndexes?.forEach((item) => {
            selectedStatusesTotal += statuses[item];
        });
        dispatch(updateStatusesAmounts(selectedStatusesTotal));
    }, [dispatch, selectedIndexes, totalInProgress, totalOnHold, totalCompleted]);

    return (
        <>
            <FilterDropdown
                id={'Statuses'}
                name={translate('ClearanceStatus_Label')}
                options={shipmentStatuses}
                optionsLabel={translate('FilterByStatus_Label')}
                selection={selectedIndexes}
                handleSelection={handleApply}
            />
            <FilterDropdown
                id={'MOT'}
                name={translate('ModeOfTransport_Label')}
                options={MOTFilter}
                optionsLabel={translate('FilterByTransport_Label')}
                selection={MOTSelectedIndexes}
                handleSelection={handleApply}
            />
            <FilterDropdown
                id={'Milestones'}
                name={translate('TableGridCurrentMilestone_Label')}
                options={milestones}
                optionsLabel={translate('FilterByMilestone_Label')}
                selection={MilestoneSelectedIndexes}
                handleSelection={handleApply}
            />
        </>
    );
};

export default DropdownFilters;
