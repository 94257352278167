export const webAPIbaseURL = 'http://localhost:54729/';
export const filtersConstants = {
    ALL_TYPES: 'allTypes',

    SEARCH_SECTION_CLIENT_NUMBER: 'clientNumber',
    SEARCH_SECTION_MAIN: 'main',
    SEARCH_SECTION_DATE: 'date',
    SEARCH_SECTION_DOC_TYPE: 'docType',
    SEARCH_SECTION_OTHER: 'other',
    SEARCH_SECTION_LOCATON: 'location',
    SEARCH_SECTION_MEASURES: 'measures',
    SEARCH_SECTION_PARTIES: 'parties',
    SEARCH_SECTION_PARTS: 'parts',
    SEARCH_SECTION_REF_NUMBERS: 'referenceNumber'
};
export const gridSettingsConstants = {
    us: 'Shipping_Documents_US',
    ca: 'Shipping_Documents_CA'
};

export const DateRangeFilter = [
    { id: 'LAST_30', label: 'DateRangeThirtyDays_Label' },
    { id: 'LAST_60', label: 'DateRangeSixtyDays_Label' },
    { id: 'LAST_90', label: 'DateRangeNinetyDays_Label' },
    { id: 'THIS_YEAR', label: 'DateRangeThisYear_Label' },
    { id: 'LAST_YEAR', label: 'DateRangeLastYear_Label' },
    { id: 'ALL_TIME', label: 'DateRangeAllTime_Label' },
    { id: 'CUSTOM', label: 'DateRangeCustom_Label' }
];
