import { faBoxOpen, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { shipmentDataSourceConstants } from '../../../portal/common/constants/constants-portal';
import Button from '../../../portal/common/features/Button';
import ClientChooser from '../../../portal/common/features/ClientChooser/ClientChooser';
import HeaderBar from '../../../portal/common/features/HeaderBar/HeaderBar';
//import { ShipmentDataSource } from '../../../portal/common/features/ShipmentDataSource';
import useChangeUserCountry from '../../../portal/common/hooks/useChangeUserCountry';
import useClientDimensions from '../../../portal/common/hooks/useClientDimensions';
import useMediaQuery from '../../../portal/common/hooks/useMediaQuery';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { ClientSelectionSlice, HydrationSlice } from '../../../portal/common/models/ReduxSlices';
import { SaveShipmentDataSource } from '../../../portal/redux/actions/shipmentDataSource';
import { DateRangeFilter as DateFilterConstants } from '../../common/constants/constants-clearances';
import hasFilterBarFixedWidth from '../../common/functions/hasFilterBarFixedWidth';
import { DateRangeSlice, ShipmentGridSettingsSlice, ShipmentStatusSlice } from '../../common/models/ReduxSlices';
import { resetDateRange } from '../../redux/reducers/dateRangeSlice';
import { resetShipmentGridSettings } from '../../redux/reducers/shipmentGridSettingsSlice';
import { resetStatus } from '../../redux/reducers/shipmentStatusSlice';
import { RootState } from '../../redux/store';
import DateRangeFilter from './filtersbar/DateRangeFilter';
import DropdownFilters from './filtersbar/DropdownFilters';
import ClearancesSearchBox from './filtersbar/searchbox/ClearancesSearchBox';
import ShipmentsGrid from './shipmentsgrid/ShipmentsGrid';
import ShipmentsGridCaption from './shipmentsgrid/ShipmentsGridCaption';

const ShipmentList = () => {
    const translate = useTranslation();
    const scrollableDivRef = useRef<HTMLDivElement>(null);
    const scrollableDivItems = scrollableDivRef.current && Array.from(scrollableDivRef.current['children']);
    const lastChild = scrollableDivRef && scrollableDivRef.current && Array.from(scrollableDivRef.current?.children).reverse()[0];
    const direction = {
        RIGHT: scrollableDivItems ? scrollableDivItems.length - 1 : 0,
        LEFT: 0
    };
    const [scrollRight, setScrollRight] = useState(true);
    const resize = useClientDimensions();
    // Fix filter bar width once it grows to a certain length
    const [filterBarHasFixedWidth, setFilterBarHasFixedWidth] = useState<boolean>();
    const [filtersHaveSelected, setFiltersHaveSelected] = useState(false);

    const { dateRangeSelected } = useSelector<RootState, DateRangeSlice>((state) => state.dateRange);
    const { statusSelected } = useSelector<RootState, ShipmentStatusSlice>((state) => state.shipmentStatus);
    const { columnFilters, MOTSelectedIndexes, MilestoneSelectedIndexes } = useSelector<RootState, ShipmentGridSettingsSlice>(
        (state) => state.shipmentGridSettings
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const mounted = useRef(false);
    const mobileView = useMediaQuery(0, 515);
    const { updateClientSelectionCountry } = useChangeUserCountry();
    const { initialClientSelection, clientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const { shipmentDataSource, isShipmentDataSourceLoading, isSaveDataSourceLoading } = useSelector<RootState, HydrationSlice>(
        (state) => state.hydration
    );
    const { isLoadingUpdate, isLoadingGet } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const dataSourceTranslations = {
        RealTimeData: translate('RealTimeData_Label'),
        MonitorClearanceStatuses: translate('MonitorClearanceStatuses_Label'),
        UpdatedRealTime: translate('UpdatedRealTime_Label'),
        CompleteEntryData: translate('CompleteEntryData_Label'),
        ComprehensiveClearanceData: translate('ComprehensiveClearanceData_Label'),
        UpdatedPeriodically: translate('UpdatedPeriodically_Label')
    };

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    // Determines showing scroll navigation buttons
    useEffect(() => {
        setTimeout(() => {
            if (mounted.current) {
                setFilterBarHasFixedWidth(hasFilterBarFixedWidth({ scrollableDivRef, filtersHaveSelected }));
                dateRangeSelected.id !== DateFilterConstants[0].id ||
                MOTSelectedIndexes.length ||
                MilestoneSelectedIndexes.length ||
                statusSelected.length < 3
                    ? setFiltersHaveSelected(true)
                    : setFiltersHaveSelected(false);

                !scrollRight && lastChild?.scrollIntoView(false);
            }
        }, 0);
    }, [
        resize,
        scrollRight,
        filtersHaveSelected,
        lastChild,
        columnFilters,
        statusSelected,
        dateRangeSelected,
        MOTSelectedIndexes,
        MilestoneSelectedIndexes,
        scrollableDivRef,
        filterBarHasFixedWidth,
        setFilterBarHasFixedWidth
    ]);

    // Redirect to CAD
    // useEffect(() => {
    //     if (
    //         shipmentDataSource === shipmentDataSourceConstants.ca &&
    //         !isLoadingUpdate &&
    //         !isLoadingGet &&
    //         !isSaveDataSourceLoading &&
    //         !isShipmentDataSourceLoading
    //     ) {
    //         if (clientSelection) {
    //             if (country?.Code === 'us') updateClientSelectionCountry(clientSelection, 'ca');

    //             history.push('/CompleteEntryData');
    //         }
    //     }
    // }, [
    //     shipmentDataSource,
    //     history,
    //     isSaveDataSourceLoading,
    //     isLoadingUpdate,
    //     isLoadingGet,
    //     isShipmentDataSourceLoading,
    //     country?.Code,
    //     clientSelection,
    //     updateClientSelectionCountry,
    //     dispatch
    // ]);

    const handleDataSourceSelection = (index: number) => {
        if (index === 0) {
            if (country?.Code) dispatch(SaveShipmentDataSource({ PreferenceValue: shipmentDataSourceConstants.us, Country: 'ca' }));
        } else if (index === 1) {
            if (country?.Code) dispatch(SaveShipmentDataSource({ PreferenceValue: shipmentDataSourceConstants.ca, Country: 'ca' }));
        }
    };

    const clearAllFilters = () => {
        setFilterBarHasFixedWidth(hasFilterBarFixedWidth({ scrollableDivRef, filtersHaveSelected }));
        dispatch(resetStatus());
        dispatch(resetShipmentGridSettings());
        dispatch(resetDateRange());
    };

    return (
        <>
            {/* {shipmentDataSource !== shipmentDataSourceConstants.ca && ( */}
            <div className='d-flex flex-column flex-fill'>
                {/* Header bar */}
                <HeaderBar columnsLayout={country && country.Code === 'ca'} mobileView={mobileView}>
                    <div className={'d-flex align-items-center'}>
                        <span className='badge badge-circular badge-info mr-3'>
                            <FontAwesomeIcon icon={faBoxOpen} />
                        </span>
                        <h1 className={'m-0 mr-2'}>{translate('SearchClearancesTitle_Label')}</h1>
                    </div>

                    <div
                        className={`d-flex ${country && country.Code === 'ca' && mobileView ? 'dropdown-examples' : ''} `}
                        style={{ alignItems: 'unset' }}
                    >
                        {/* {country && country.Code === 'ca' && (
                                <ShipmentDataSource
                                    translations={dataSourceTranslations}
                                    dataSource={shipmentDataSource}
                                    handleDataSource={handleDataSourceSelection}
                                    shipmentDataSourceConstants={shipmentDataSourceConstants}
                                />
                            )} */}
                        <ClientChooser />
                    </div>
                </HeaderBar>

                {/* Filter bar */}
                {/* <FilterBar>  */}
                <ClearancesSearchBox />
                <nav className='bg-white border-bottom' id='FilterBarWithDropdowns'>
                    <div className='d-flex justify-content-start'>
                        <div
                            className={`filters-and-nav-arrows-wrapper ${filterBarHasFixedWidth ? 'fixed-width' : ''} ${
                                filtersHaveSelected ? 'clear-all-showing' : ''
                            }`}
                        >
                            <div className='scrollable-filter-area d-flex flex-nowrap align-items-center py-3 pr-3' ref={scrollableDivRef}>
                                <div className='font-weight-medium pl-3 pr-2'>{translate('Filters_Label')}</div>
                                <DateRangeFilter />
                                <DropdownFilters />
                                {/* <ClearancesSearchBox /> */}
                            </div>
                            <Button
                                className='btn btn-tertiary btn-sm btn-scroll-left shadow'
                                onClick={() => {
                                    scrollableDivItems && scrollableDivItems[direction.LEFT].scrollIntoView(false);
                                    setScrollRight(true);
                                }}
                                style={{
                                    display:
                                        filterBarHasFixedWidth &&
                                        scrollableDivRef.current &&
                                        scrollableDivRef.current.offsetWidth <= scrollableDivRef.current.scrollWidth &&
                                        !scrollRight
                                            ? 'block'
                                            : 'none'
                                }}
                                variant={'link'}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </Button>
                            <Button
                                className='btn btn-tertiary btn-sm btn-scroll-right shadow'
                                onClick={() => {
                                    scrollableDivItems && scrollableDivItems[direction.RIGHT].scrollIntoView(false);
                                    setScrollRight(false);
                                }}
                                style={{
                                    display:
                                        filterBarHasFixedWidth &&
                                        scrollableDivRef.current &&
                                        scrollableDivRef.current.offsetWidth <= scrollableDivRef.current.scrollWidth &&
                                        scrollRight
                                            ? 'block'
                                            : 'none'
                                }}
                                variant={'link'}
                            >
                                <FontAwesomeIcon icon={faChevronRight} />
                            </Button>
                        </div>

                        {filtersHaveSelected ? (
                            <div
                                className={`clear-all-filters d-flex flex-row align-items-center ${
                                    filterBarHasFixedWidth
                                        ? 'fixed-width text-center border-left shadow-sm justify-content-center'
                                        : 'justify-content-start'
                                }`}
                            >
                                <Button
                                    className={`no-bold-underline ${filterBarHasFixedWidth ? '' : 'text-decoration-none'}`}
                                    variant={'link'}
                                    onClick={() => clearAllFilters()}
                                >
                                    {translate('ClearAll_Label')}
                                </Button>
                            </div>
                        ) : null}
                    </div>
                    {/* </FilterBar> */}
                </nav>
                <ShipmentsGridCaption />
                <ShipmentsGrid />
            </div>
            {/* )} */}
        </>
    );
};

export default ShipmentList;
