import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Badge, { BadgeProps } from '../../../common/features/Badge';
import CloseButton from '../../../common/features/CloseButton';
import { DownloadLobbyItem, HydrationSlice, LanguageSlice } from '../../../common/models/ReduxSlices';
import { removeUnreadLobbyItem } from '../../../redux/reducers/alertsSlice';
import { RootState } from '../../../redux/store';
import { createDateForAlert } from '../../functions/parser';
import useTranslationHydration from '../../hooks/useTranslationHydration';
import Button from '../Button';
import ToastWrapper from '../Wrappers/ToastWrapper';

const DownloadLobbyToastCard: React.FC<DownloadLobbyItem> = ({
    inProgress,
    imageUrl,
    createdOn,
    documentCount,
    entryNumbers,
    hasError,
    packageId
}) => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { languageSelected } = useSelector<RootState, LanguageSlice>((state) => state.language);

    const [showAlert, setShowAlert] = useState(true);
    const translate = useTranslationHydration();
    const dispatch = useDispatch();

    const timeout = user?.PortalTimeoutLong || 8000;

    // Close alert and remove from unread after clicking the "close" button
    const closeAlert = () => {
        if (!inProgress) {
            dispatch(removeUnreadLobbyItem(packageId));
        }
        setShowAlert(false);
    };

    const handleDownload = () => {
        if (imageUrl) window.open(imageUrl, '_blank');
    };

    const titleLabel = hasError
        ? translate('DownloadLobbyErrorTitle_Label')
        : inProgress
        ? translate('DownloadLobbyTitle_Label')
        : translate('DownloadReady_Label');

    const descLabel = hasError
        ? translate('DownloadLobbyDesc4_Label') // Error desc
        : inProgress
        ? translate('DownloadLobbyDesc_Label') // Loading
        : entryNumbers.length
        ? translate('DownloadLobbyDesc2_Label', entryNumbers.length, documentCount) // Documents download
        : translate('DownloadLobbyDesc3_Label'); // Header/Line download

    const badgeInfo: { type: BadgeProps['type']; text: string } = hasError
        ? { type: 'danger', text: translate('ProcessingError_Label') }
        : inProgress
        ? { type: 'info', text: translate('ProcessingRequest_Label') }
        : { type: 'success', text: translate('ProcessingComplete_Label') };

    return (
        <>
            {showAlert && (
                <ToastWrapper
                    type={'on-hold-alert'}
                    timeout={timeout}
                    onClose={() => {
                        if (!inProgress) {
                            dispatch(removeUnreadLobbyItem(packageId));
                        }
                    }}
                >
                    <div className='toast-body alert-toasts d-flex flex-column align-items-start p-4'>
                        <div
                            style={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            {/* Badge */}
                            <Badge className='mb-2' type={badgeInfo.type}>
                                {badgeInfo.text}
                            </Badge>

                            {/* Date and Close button */}
                            <div style={{ display: 'flex', marginLeft: '1rem', alignItems: 'start' }}>
                                <p style={{ color: '#3c5f88', opacity: 0.6, marginBottom: '0.5rem' }}>
                                    {createDateForAlert(createdOn, languageSelected?.locale)}
                                </p>
                                <CloseButton
                                    type='toast'
                                    style={{ position: 'relative', top: '0.5rem', right: 0, marginLeft: '0.5rem' }}
                                    onClick={closeAlert}
                                />
                            </div>
                        </div>
                        {/* Title */}
                        <p className='toast-title' style={{ width: '100%', marginBottom: '0.5rem' }}>
                            <strong>{titleLabel}</strong>
                        </p>
                        {/* Description */}
                        <p className='alert-card-desc mb-0'>{descLabel}</p>

                        {/* Download button */}
                        {hasError ? null : inProgress ? (
                            <Button variant='tertiary-blue' className='mt-3' onClick={closeAlert}>
                                {translate('DownloadLobbyButton_Label')}
                            </Button>
                        ) : (
                            <Button variant='tertiary-blue' className='mt-3' onClick={handleDownload}>
                                {translate('DownloadButton_Label')}
                            </Button>
                        )}
                    </div>
                </ToastWrapper>
            )}
        </>
    );
};

export default DownloadLobbyToastCard;
