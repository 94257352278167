import { process } from '@progress/kendo-data-query';
import { GridColumn as Column, Grid, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import '@progress/kendo-theme-default/dist/all.css';
import { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import GridLoadingOverlay from '../../../../portal/common/features/Grid/GridLoadingOverlay';
import RowWithButton from '../../../../portal/common/features/Grid/RowWithButton';
import useGridLanguage from '../../../../portal/common/hooks/useGridLanguage';
import useMediaQuery from '../../../../portal/common/hooks/useMediaQuery';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { GridSettingsSlice, HydrationSlice, LanguageSlice } from '../../../../portal/common/models/ReduxSlices';
import { AdminRoles } from '../../../common/constants/constants-team';
import '../../../common/css/custom-teams.css';
import ChangeUserStatusModal from '../../../common/features/ChangeUserStatusModal';
import StatusAlerts from '../../../common/features/StatusAlerts';
import { StatusUserSlice, UserDetailsSlice, UserTeamSlice } from '../../../common/models/ReduxSlices';
import { TeamMember, TransformedTeamMember } from '../../../common/models/TeamMember';
import { setDataState, setTotalItems } from '../../../redux/reducers/teamGridSettingsSlice';
import { setEditMember } from '../../../redux/reducers/userSlice';
import { RootState } from '../../../redux/store';
import { ColumnMenuCheckboxFilter, ColumnMenuDateFilter, ColumnMenuSearchText } from './ColumnFilters';
import FormattedGridCell from './FormattedGridCell';

interface TeamsTableProps {
    teamMembers: TransformedTeamMember[];
}

const TeamsTable: React.FC<TeamsTableProps> = ({ teamMembers }) => {
    const { pageable, dataState, totalItems } = useSelector<RootState, GridSettingsSlice>((state) => state.teamGridSettings);
    const { team, isLoading } = useSelector<RootState, UserTeamSlice>((state) => state.user);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { statusMessage, showStatusModal, isStatusLoading } = useSelector<RootState, StatusUserSlice>((state) => state.statusUser);
    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const { newUsersAmount } = useSelector<RootState, UserDetailsSlice>((state) => state.userDetails);
    const gridRef = useRef<any>();
    const dispatch = useDispatch();
    const translate = useTranslation();
    const { push } = useHistory();
    const gridLanguage = useGridLanguage(selectedLanguage);
    const extraLargeSize = useMediaQuery(1890);

    const userArrayLoop = new Array(newUsersAmount || 0).fill('user');
    const row = document.querySelector(`.k-master-row`) || null;

    useEffect(() => {
        if (newUsersAmount > 0 && row) {
            userArrayLoop.map((item, index) => {
                return document.querySelector(`.k-master-row:nth-child(n+${index + 1})`)?.classList?.add('new-row-highlight');
            });
        }
    }, [row, newUsersAmount, userArrayLoop]);

    // Update the total items that are responsible to show/hide pagination
    useEffect(() => {
        if (!gridRef.current) return;

        dispatch(setTotalItems(gridRef.current.props.data.total));
    }, [dataState, teamMembers.length, dispatch]);

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        dispatch(setDataState(e.dataState));
    };
    const redirectToUserDetails = (member: TeamMember) => {
        if (team?.SuperiorId !== member?.UserId) {
            dispatch(setEditMember(member));
            return push(`/Team/TeamMember?userId=${member?.UserId}`);
        }
    };
    const handleTagEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Add_User_Team_CPEvt',
                location: 'Profile'
            }
        });
    };
    return (
        <>
            {AdminRoles.find((element) => element.id === team?.UserRole) &&
            user?.Roles !== undefined &&
            (user.Roles.includes('TeamManagement_Edit') || user.Roles.includes('TeamManagement_Create')) ? (
                <Link to='/Team/CreateNewUser'>
                    <div onClick={handleTagEvent}>
                        <RowWithButton extraRowClasses='pl-2' element={'.k-grid.teams-grid-table'}>
                            {translate('TeamTableAddNewUser_Label')}
                        </RowWithButton>
                    </div>
                </Link>
            ) : null}
            <LocalizationProvider language={gridLanguage.language}>
                <IntlProvider locale={gridLanguage.locale}>
                    <div className='d-flex position-relative flex-fill'>
                        <Grid
                            className='teams-grid-table flex-fill'
                            data={process(teamMembers, dataState)}
                            pageable={totalItems < 10 ? false : pageable}
                            sortable={true}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            ref={gridRef}
                            onRowClick={(event) => redirectToUserDetails(event.dataItem)}
                        >
                            <Column
                                field='Name'
                                title={translate('Name_Label')}
                                columnMenu={ColumnMenuSearchText}
                                width={extraLargeSize ? 'calc(100% - 1470px)' : '300px'}
                            />
                            <Column
                                field='Email'
                                title={translate('Email_Label')}
                                columnMenu={ColumnMenuSearchText}
                                width={extraLargeSize ? 'calc(100% - 1470px)' : '400px'}
                            />
                            <Column
                                field='Status'
                                title={translate('Status_Label')}
                                cell={FormattedGridCell}
                                columnMenu={ColumnMenuCheckboxFilter}
                                width={extraLargeSize ? 'calc(100% - 1470px)' : '280px'}
                            />
                            <Column
                                field='LastLogin'
                                title={translate('LastLogin_Label')}
                                cell={FormattedGridCell}
                                columnMenu={ColumnMenuDateFilter}
                                width={extraLargeSize ? 'calc(100% - 1470px)' : '285px'}
                            />
                            <Column
                                field='Expires'
                                title={translate('Expires_Label')}
                                cell={FormattedGridCell}
                                columnMenu={ColumnMenuDateFilter}
                                width={extraLargeSize ? 'calc(100% - 1470px)' : '255px'}
                            />
                            <Column field='Actions' title=' ' cell={FormattedGridCell} sortable={false} locked width='50px' />
                        </Grid>
                        {(isLoading || isStatusLoading) && team && team.TeamMembers && team.TeamMembers.length > 0 && (
                            <GridLoadingOverlay />
                        )}
                    </div>
                </IntlProvider>
            </LocalizationProvider>
            {showStatusModal && <ChangeUserStatusModal />}
            {statusMessage && <StatusAlerts name='userStatus' />}
            {newUsersAmount > 0 && <StatusAlerts name='newUser' />}
        </>
    );
};

export default TeamsTable;
