import { process } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import React, { useEffect, useRef } from 'react';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { ClientServices } from '../models/TeamMember';
import ClientsAndPermissionsTableHeading from './ClientsAndPermissionsTableHeading';

interface ClientsAndPermissionsTableProps {
    title: string;
    clientServices: ClientServices[];
    adminRole: string | null | undefined;
    disabled?: boolean;
    onSelect: (options: { number: string; field: string; isChecked: boolean; title: string; clientServicesIdx: number }) => void;
    onToggleSelectAll: (options: { title: string; featureName: string }) => void;
}

const ClientsAndPermissionsTable: React.FC<ClientsAndPermissionsTableProps> = ({
    disabled,
    title,
    clientServices,
    adminRole,
    onSelect,
    onToggleSelectAll
}) => {
    const translate = useTranslation();
    const delayDispatch = useRef<NodeJS.Timeout>();

    // clearTimeout "delayDispatch.current" on unmount
    useEffect(() => {
        return () => {
            clearTimeout(delayDispatch.current);
        };
    }, []);

    const createDataState = (dataState: any) => {
        return {
            result: process(clientServices, dataState),
            dataState: dataState
        };
    };

    // all checkboxes(except Clearances) should be disabled if client account status is populated(inactive)
    const shouldDisableCheckbox = (index: number, field: string) => {
        const status = clientServices[index]?.StatusCodeName;
        if (status && status !== 'Active' && field !== 'My Livingston Portal' && field !== 'Clearances') return true;

        return false;
    };

    const addCheckbox = (props: GridCellProps) => {
        const number = props.dataItem.Number;
        const field = props.field || '';
        const clientServicesIdx = props.dataIndex;
        var id = clientServicesIdx + number + field;

        const isChecked = props?.dataItem?.Features?.find((feature: any) => feature?.Name === props?.field)?.IsSelected;

        return (
            <td>
                <form className='d-flex justify-content-center'>
                    <div className='custom-control custom-checkbox'>
                        <input
                            type='checkbox'
                            className='custom-control-input'
                            id={id}
                            defaultChecked={isChecked}
                            disabled={disabled || shouldDisableCheckbox(clientServicesIdx, props.field!)}
                            onChange={() =>
                                (delayDispatch.current = setTimeout(
                                    () => onSelect({ number, field, isChecked, title, clientServicesIdx }),
                                    200
                                ))
                            }
                        />
                        <label className='custom-control-label' htmlFor={id} />
                    </div>
                </form>
            </td>
        );
    };

    const addHeading = (props: any) => {
        return (
            <ClientsAndPermissionsTableHeading
                adminRole={adminRole}
                featureName={props.title}
                title={title}
                onToggleSelectAll={onToggleSelectAll}
            />
        );
    };

    const numberCaptionCell = (props: GridCellProps) => {
        let cellContent;

        const clientServicesIdx = props.dataIndex;
        cellContent = clientServices[clientServicesIdx].Number + '-' + clientServices[clientServicesIdx].CaptionNumber;

        return <td>{cellContent}</td>;
    };

    let initialState = createDataState({
        // take: 20,
        skip: 0
    });

    const [result, setResult] = React.useState(initialState.result);
    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event: any) => {
        let updatedState = createDataState(event.dataState);

        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    return (
        <>
            <div className='table-heading py-3 px-2'>{title}</div>
            <Grid
                className='clients-and-permissions-table mb-4'
                data={clientServices}
                {...dataState}
                onDataStateChange={dataStateChange}
                total={clientServices.length}
            >
                <Column field='Number' title={translate('ClientsAndPermissionsNumber_Label')} cell={numberCaptionCell} />
                <Column field='Name' title={translate('ClientsAndPermissionsName_Label')} />
                {result &&
                    result.data[0]?.Features?.map((feature: any) => {
                        return (
                            <Column
                                key={feature.Name}
                                field={feature.Name}
                                title={feature.Name}
                                headerCell={addHeading}
                                cell={addCheckbox}
                            />
                        );
                    })}
            </Grid>
        </>
    );
};

export default ClientsAndPermissionsTable;
