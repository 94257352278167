import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusAlert from '../../../../portal/common/features/StatusAlert';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { ClearanceSubscribersSlice, ShipmentDetailsSlice } from '../../../common/models/ReduxSlices';
import {
    clearNewSubscribers,
    resetSelfSubscriptionUpdate,
    resetSubscribe,
    resetSubscriptionUpdate,
    resetUnsubscribe
} from '../../../redux/reducers/clearanceSubscribersSlice';
import { RootState } from '../../../redux/store';

const SubscriptionStatusAlerts = () => {
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state.shipmentDetails);
    const { isLoading, subscribe, subscriptionUpdate, selfSubscription, selfSubscriptionUpdate, unsubscribe, subscribers } = useSelector<
        RootState,
        ClearanceSubscribersSlice
    >((state) => state.clearanceSubscribers);
    const dispatch = useDispatch();
    const translate = useTranslation();

    // Reset all statuses on unmount
    useEffect(() => {
        return () => {
            dispatch(resetSubscriptionUpdate());
            dispatch(resetSelfSubscriptionUpdate());
            dispatch(resetSubscribe());
            dispatch(resetUnsubscribe());
        };
    }, [dispatch]);

    useEffect(() => {
        const newSubsDelay = setTimeout(() => {
            dispatch(clearNewSubscribers());
        }, 3000);
        return () => clearTimeout(newSubsDelay);
    }, [subscribers?.length, dispatch]);

    if (
        isLoading ||
        subscriptionUpdate.isLoading ||
        selfSubscriptionUpdate.isLoading ||
        selfSubscription.isLoading ||
        subscribe.isLoading ||
        unsubscribe.isLoading
    )
        return null;

    return (
        <>
            {/* Subscription update */}
            {subscriptionUpdate.data && !subscriptionUpdate.error && subscriptionUpdate.alertMessage && subscriptionUpdate.alertType && (
                <StatusAlert message={translate(subscriptionUpdate.alertMessage)} type={subscriptionUpdate.alertType} />
            )}

            {/* Subscribe */}
            {subscribe.data && !subscribe.error && (
                <StatusAlert
                    message={translate('SubscribersAdded_Label', subscribe.data.SubscriptionsCount, subscribe.data.EntryTransactionNumber)}
                    type='success'
                />
            )}

            {/* Unsubscribe */}
            {unsubscribe.data && !unsubscribe.error && (
                <StatusAlert message={translate('SubscribersRemoved_Label', unsubscribe.removedSubscribersAmount)} type='success' />
            )}

            {/* Self subscription update */}
            {selfSubscription.data &&
                !selfSubscription.error &&
                selfSubscriptionUpdate.data &&
                !selfSubscriptionUpdate.error &&
                selfSubscriptionUpdate.alertMessage &&
                selfSubscriptionUpdate.alertType &&
                shipmentDetails?.EntryNumber && (
                    <StatusAlert
                        message={
                            selfSubscriptionUpdate.data.SubscriptionStatusCode === 'SUBSCRIBED' &&
                            selfSubscription.data &&
                            selfSubscription.data.SubscriptionIds?.length
                                ? translate(selfSubscriptionUpdate.alertMessage)
                                : selfSubscriptionUpdate.data.SubscriptionStatusCode === 'SUBSCRIBED' &&
                                  !selfSubscription.data?.SubscriptionIds?.length
                                ? translate(selfSubscriptionUpdate.alertMessage)
                                : translate(selfSubscriptionUpdate.alertMessage, shipmentDetails.EntryNumber)
                        }
                        type='success'
                    />
                )}
        </>
    );
};

export default SubscriptionStatusAlerts;
