import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-portal';
import { API_HYDRATION_PATH, Translations } from '../../common/constants/constants-portal';
import { HydrationData } from '../../common/models/ResponseData';
import { GET_USER_DETAILS } from './actionTypes';
import { initializeGTM } from '../../../utils/gtm';

export const GetUserDetails = createAsyncThunk(GET_USER_DETAILS, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.post<HydrationData>(`${API_HYDRATION_PATH}/hydration`, {
            phraseGroup: Translations.PhraseGroups.Hydration
        });

        // Initialize GTM for Portal
        initializeGTM(response?.data?.Hydration?.UserId);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
