import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DateFilterSlice } from '../../common/models/ReduxSlices';

const initialState: DateFilterSlice = {
    date: 'DateRangeAllTime_Label',
    dateDropdownLabel: 'DateRangeAllTime_Label'
};
const dateFilterSlice = createSlice({
    name: 'dateFilterSlice',
    initialState,
    reducers: {
        setNewDate(state, action: PayloadAction<string>) {
            state.date = action.payload;
        },
        setDateDropdownLabel(state, action: PayloadAction<string>) {
            state.dateDropdownLabel = action.payload;
        }
    }
});
export const { setNewDate, setDateDropdownLabel } = dateFilterSlice.actions;

export default dateFilterSlice.reducer;
