import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ToastSlice } from '../../models/ReduxSlices';
import StatusAlert from '../StatusAlert';

const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { toast } = useSelector<RootState, ToastSlice>((state) => state.toast);

    return (
        <>
            {toast && <StatusAlert {...toast} />}
            {children}
        </>
    );
};

export default ToastProvider;
