import { format } from 'react-string-format';

/**
 * These utils are from mlp utils
 */
export const formatTranslated = function (toTranslate, mlpTranslations) {
    if (toTranslate === undefined || toTranslate === '') {
        return '';
    }
    const indexDouble = toTranslate.indexOf('@@');
    let translatedArr;
    let key;
    if (indexDouble >= 0) {
        translatedArr = toTranslate.split('@@');
        key = translatedArr[0];
    } else {
        translatedArr = Array.prototype.slice.call(arguments);
        key = translatedArr.shift();
    }

    if (mlpTranslations[key] === undefined) {
        return 'Untranslated:[' + key + ']';
    } else if (translatedArr.length === 3) {
        return format(mlpTranslations[key], translatedArr[1], translatedArr[2]);
    } else if (translatedArr.length === 2) {
        return format(mlpTranslations[key], translatedArr[1]);
    } else {
        return mlpTranslations[key];
    }
};

export const getSearchTypesBySelectedServices = (vm) => {
    if (!vm?.ClientServiceSelection?.Countries.length) return;
    const selectedCountry = vm.ClientServiceSelection.Countries.find((country) => country.IsSelected === true);
    const selectedCountryCode = selectedCountry !== undefined ? selectedCountry.Code : '';

    const selectedServices = vm.ClientServiceSelection.ClientServices.filter(
        (service) => service.IsSelected === true && service.CountryCode === selectedCountryCode
    );

    // If no clients are selected, only users with unrestricted access should see the results
    if ((selectedServices === undefined || selectedServices.length <= 0) && !vm.ClientServiceSelection.HasUnrestrictedAccess) {
        return [];
    }
    return vm.SearchTypes.filter((type) => type.CountryCode === selectedCountryCode);
};

export const isNumber = (value) => {
    return typeof value === 'number';
};

export const sortFunction = (value1, value2, order) => {
    if (isNumber(value1) && isNumber(value2)) {
        return order === 'DESC' ? value2 - value1 : value1 - value2;
    } else {
        // Treating undefined values as empty strings, because localeCompare function only sorts array of strings
        // const val1 = value1 ? value1 : '';
        // const val2 = value2 ? value2 : '';
        // localeCompare function is putting empty strings on the top of sorted array
        // return order === 'DESC' ? val2.localeCompare(val1) : val1.localeCompare(val2);

        // Treating empty strings as undefined values
        const val1 = value1 && value1.toString().trim() !== '' ? value1 : undefined;
        const val2 = value2 && value2.toString().trim() !== '' ? value2 : undefined;
        // Handling undefined values in sorted array, putting them always on the bottom of sorted array
        let exp = !val1 - !val2;
        return order === 'DESC' ? exp || -(val1 > val2) || +(val1 < val2) : exp || +(val1 > val2) || -(val1 < val2);
    }
};

export const sortBy = (array, property, order) => {
    return array.sort((a, b) => sortFunction(a[property], b[property], order));
};

export const numberWithCommas = (number) => {
    return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

//2023-10-19T16:47:23.526Z or '2023-10-19' to MM-DD-YYYY
export const convertDateMDY = (dt) => {
    if (dt) {
        const date = dt.includes('T') ? dt.substring(0, dt.indexOf('T')).split('-') : dt.includes('-') && dt.split('-');
        const month = Number(date[1]) < 10 ? `0${Number(date[1])}` : `${Number(date[1])}`;
        const day = Number(date[2]) < 10 ? `0${Number(date[2])}` : `${Number(date[2])}`;

        return `${month}/${day}/${date[0]}`;
    }
};

export const setGroupAttributes = (imageGroup) => {
    if (imageGroup.SearchTypeCode === 'CASHP' || imageGroup.SearchTypeCode === 'CACLVS') {
        let groupAttributes = imageGroup.GroupAttributes;

        groupAttributes = [...groupAttributes].map((item) => {
            if (item.Name === '_transactionNumber') {
                return (item = {
                    ...item,
                    GridSortLevel: 1,
                    GridSortDirection: 'ASC',
                    IsGridSortable: true
                });
            } else if (item.Name === '_imageDate') {
                return (item = {
                    ...item,
                    GridSortLevel: 2
                });
            } else if (item.Name === '_folderDate') {
                return (item = {
                    ...item,
                    GridSortLevel: 0,
                    GridSortDirection: '',
                    IsGridSortable: false
                });
            } else {
                return item;
            }
        });

        return groupAttributes;
    }
    return imageGroup.GroupAttributes;
};

export const setUpFilters = (filters, entryNo) => {
    const updatedFilters = [...filters].map((item) => {
        if (item.Name === 'Main Search' && item.SearchTypeCodes.includes('USSHP')) {
            return {
                ...item,
                SearchTypeCodes: ['USSS'],
                Attribute: 'allTypes',
                Value: entryNo
            };
        } else if (item.Level === 'clientNumber') {
            return {
                ...item,
                Attribute: 'ClientNo',
                SearchTypeCodes: ['USSS']
            };
        }

        return item;
    });

    return updatedFilters;
};

export const generateGridKey = (currentKey) => {
    let newKey = Math.floor(Math.random() * 1000) + 1;

    if (newKey === currentKey) {
        newKey = (newKey % 1000) + 1;
    }

    return newKey;
};
