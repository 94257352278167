import { faDownload, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownMenuButton from '../../../../../portal/common/features/DropdownMenuButton';
import { checkAllFalsy } from '../../../../../portal/common/functions/utils';
import useGTMEventTracking from '../../../../../portal/common/hooks/useGTMEventTracking';
import useNotifDropdownToggle from '../../../../../portal/common/hooks/useNotifDropdownToggle';
import useTranslation from '../../../../../portal/common/hooks/useTranslation';
import { ClientSelectionSlice } from '../../../../../portal/common/models/ReduxSlices';
import { configFilters } from '../../../../common/functions/documentModelFunctions';
import { DocumentsSlice, ShipmentDetailsSlice, ShipmentListSlice } from '../../../../common/models/ReduxSlices';
import { DownloadDocuments, ExportHeaderAndDetail } from '../../../../redux/actions/clearanceDocuments';
import { RootState } from '../../../../redux/store';

const DownloadDropdown = () => {
    const { documents, headerDetails, lineDetails } = useSelector<RootState, DocumentsSlice>((state) => state.clearanceDocuments);
    const { documentsIndex } = useSelector<RootState, ShipmentListSlice>((state) => state.shipmentsWithTotals);
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state.shipmentDetails);
    const { clientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const dropdownMenuRef = useRef(null);
    const { isOpen, handleToggle } = useNotifDropdownToggle(dropdownMenuRef);
    const dispatch = useDispatch();
    const translate = useTranslation();
    const selectedCountry = clientSelection?.Countries.find((country) => country.IsSelected === true)?.Code;
    const headerlineDetailsEntryRef = useRef(null);
    const documentsDownloadEntryRef = useRef(null);

    useGTMEventTracking('HL_DNLD_Entry_CLR_CPEvt', { location: 'Clearances' }, headerlineDetailsEntryRef);
    useGTMEventTracking('Doc_DNLD_Entry_CLR_CPEvt', { location: 'Clearances' }, documentsDownloadEntryRef);
    const downloadHeaderAndLine = () => {
        handleToggle();
        if (documentsIndex?.Filters && documentsIndex.ImageGroupings && headerDetails) {
            dispatch(
                ExportHeaderAndDetail({
                    shipments: [headerDetails],
                    filters: configFilters(documentsIndex, [shipmentDetails?.EntryNumber!])!,
                    imageGroupings: documentsIndex.ImageGroupings
                })
            );
        }
    };

    const downloadDocuments = () => {
        handleToggle();
        if (documents) {
            dispatch(
                DownloadDocuments({
                    fileNames: { [shipmentDetails?.EntryNumber || 'document']: documents.map((document) => document.path) },
                    ImagePackageId: 1
                })
            );
        }
    };

    return (
        <>
            {selectedCountry === 'ca' ? (
                <button
                    type='button'
                    className='btn btn-tertiary btn-sm mr-1 mb-2 m-xl-1'
                    onClick={() => downloadDocuments()}
                    disabled={!documents?.length}
                >
                    {translate('DownloadDocumentsButton_Label')}
                </button>
            ) : (
                <DropdownMenuButton
                    btnSize='small'
                    isOpen={isOpen}
                    onClick={handleToggle}
                    ref={dropdownMenuRef}
                    btnClassName='dropdown-toggle mr-2'
                    childrenDivClassName='dropdown-menu w-100 w-md-auto'
                    label={
                        <>
                            <FontAwesomeIcon icon={faDownload} className='mr-2' />
                            {translate('DownloadButton_Label')}
                        </>
                    }
                >
                    <>
                        <button
                            className='dropdown-item d-flex justify-content-between align-items-center'
                            style={{ cursor: 'pointer' }}
                            onClick={() => downloadHeaderAndLine()}
                            ref={headerlineDetailsEntryRef}
                            disabled={!checkAllFalsy(headerDetails) || !lineDetails?.length}
                        >
                            {translate('HeaderAndLineDetail_Label')}
                            <div className='icon'>
                                <FontAwesomeIcon icon={faFileDownload} className='ml-2' />
                            </div>
                        </button>

                        <button
                            className='dropdown-item d-flex justify-content-between align-items-center'
                            style={{ cursor: 'pointer' }}
                            onClick={() => downloadDocuments()}
                            ref={documentsDownloadEntryRef}
                            disabled={!documents?.length}
                        >
                            {translate('Documents_Label')}
                            <div className='icon'>
                                <FontAwesomeIcon icon={faFileDownload} className='ml-2' />
                            </div>
                        </button>
                    </>
                </DropdownMenuButton>
            )}
        </>
    );
};

export default DownloadDropdown;
