import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { REACT_APP_ASSETS_URL } from '../../portal/common/constants/constants-portal';
import useTranslation from '../../portal/common/hooks/useTranslation';
import { ClientService } from '../../portal/common/models/ResponseData';
import { caBullet, other, usBullet } from '../common/constants/constants-documents';
import { resetDateRange } from '../redux/reducers/dateRangeSlice';
import { resetDocumentsDropdownFilters } from '../redux/reducers/imagingFiltersSlice';
import { resetImagingGridSettings } from '../redux/reducers/imagingGridSettingsSlice';
import {
    resetDateFilter,
    resetDateRangeAndTypeFilters,
    resetDocTypeFilter,
    resetMOTFilter,
    resetSelectedEntries,
    resetStatusFilter,
    setSearchBarOptions,
    updateSearchFilters,
    updateSearchKeywords,
    updateShouldSubmitImageSearch,
    updateSubmittedSearchKeywords
} from '../redux/reducers/imagingSlice';
interface HeaderDetailsProps {
    countrySelected: string | undefined;
    numbersSearched: Partial<ClientService>[] | undefined;
    submitedSearchKeywordsLength: number;
}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: '7%',
    paddingRight: '5%'
};

const NoDocumentsData: React.FC<HeaderDetailsProps> = ({ countrySelected, numbersSearched, submitedSearchKeywordsLength }) => {
    const dispatch = useDispatch();
    const translate = useTranslation();

    const handleResetSearch = () => {
        // Reset all filters
        dispatch(resetDateRange());
        dispatch(resetSelectedEntries());
        dispatch(resetDocumentsDropdownFilters());
        dispatch(resetDateRangeAndTypeFilters());
        dispatch(resetImagingGridSettings());
        dispatch(resetMOTFilter());
        dispatch(resetStatusFilter(countrySelected ?? 'us'));
        dispatch(resetDateFilter(countrySelected ?? 'us'));
        dispatch(resetDocTypeFilter());

        // Reset search bar
        dispatch(setSearchBarOptions());
        const payload = {
            value: [''],
            country: countrySelected ?? ('us' as any)
        };
        dispatch(updateSearchFilters(payload));
        dispatch(updateSearchKeywords([]));
        dispatch(updateSubmittedSearchKeywords([]));
        dispatch(updateShouldSubmitImageSearch(true));
    };

    return (
        <div style={containerStyle}>
            <img
                alt='Not found error'
                className='image-150px '
                src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/scenes/scene-error-404-page-not-found.svg`}
                style={{ marginBottom: '1%' }}
            />
            <div style={{ width: '90%' }}>
                <h2 style={{ borderBottom: '2px solid white', paddingBottom: '.5%' }} className='mb-2'>
                    {submitedSearchKeywordsLength ? translate('NoMatchingResults_Label') + ' ' : translate('NoResults_Label')}
                    {numbersSearched?.map((x, index) => (
                        <React.Fragment key={x.Number}>
                            <span style={{ backgroundColor: 'rgb(242, 243, 185)' }}>{`‘${x.Number}’`}</span>
                            <span> </span>
                        </React.Fragment>
                    ))}
                </h2>
            </div>
            <h3 style={{ marginTop: '0.5%' }}>{translate('SearchTerms_Label')}</h3>
            {countrySelected === 'us' && (
                <ul style={{ marginBottom: '0' }}>
                    {usBullet.map((x) => (
                        <li key={translate(x)}>{translate(x)}</li>
                    ))}
                </ul>
            )}
            {countrySelected === 'ca' && (
                <ul style={{ marginBottom: '0' }}>
                    {caBullet.map((x) => (
                        <li key={translate(x)}>{translate(x)}</li>
                    ))}
                </ul>
            )}
            <h3 style={{ marginTop: '0.5%' }}>{translate('OtherSuggestions_Label')}</h3>
            <ul style={{ marginBottom: '.7vh' }}>
                {other.map((x) => (
                    <li key={translate(x)}>{translate(x)}</li>
                ))}
            </ul>
            <button
                style={{
                    padding: '3px 5px',
                    cursor: 'pointer',
                    borderRadius: '7%',
                    borderWidth: '1px',
                    color: 'rgb(91, 162, 233)',
                    borderColor: 'rgb(91, 162, 233)'
                }}
                onClick={handleResetSearch}
            >
                <FontAwesomeIcon icon={faUndo} /> {translate('ClearSearch_Label')}
            </button>
        </div>
    );
};

export default NoDocumentsData;
