import { Component } from 'react';
import { connect } from 'react-redux';
import CheckBox from '../../common/CheckBox/CheckBox';
import { global } from '../../constants/mlp-constants';
import * as actionCreators from '../../store/actions/index';

class ClientServicesRow extends Component {
    state = {
        roleVal: ''
    };

    componentDidMount() {
        if (this.props.userRoles) {
            this.setState({ roleVal: this.props.userRoles.find((role) => role.IsSelected).Value });
        }
        if (this.props.pendingUser) {
            this.setState({ roleVal: this.props.pendingUser.Roles.find((role) => role.IsSelected).Value });
        }
    }

    updateFeature = (index, isSelected, name) => {
        this.props.updateFeature(
            this.props.countryIndex,
            this.props.parentIndex,
            this.props.clientIndex,
            index,
            isSelected,
            this.props.area
        );
    };

    removeClientService = () => {
        this.props.removeClientService(this.props.countryIndex, this.props.parentIndex, this.props.clientIndex, this.props.area);
    };

    showTeamMembers = () => {
        this.props.showTeamMembers(this.props.clientService.ClientAdminId, this.props.area);
    };

    render() {
        let cs = JSON.parse(JSON.stringify(this.props.clientService));

        if (!this.props.readonly && !this.props.pendingUser.InternalUser) {
            const selectedFeatures = cs.Features.filter((feature) => feature.IsSelected === true);
            if (selectedFeatures.length === 0) {
                cs.ClientAdminName = '';
            } else {
                if (selectedFeatures.length === 1 && selectedFeatures[0].FeatureCode === global.PAY_FEATURE_CODE) {
                    cs.ClientAdminName = '';
                } else {
                    cs.ClientAdminName = this.props.pendingUser.FullName;
                }
            }
        }

        const getClientStatus = (number) => {
            let status;
            if (this.props.viewModel?.User?.ClientStatuses) {
                const clientStatuses = [this.props.viewModel.User.ClientStatuses];
                const client = clientStatuses.find((x) => x[number] !== undefined);

                if (client) {
                    status = client[number];
                }
            }

            if (this.props.selectedClients) {
                let client = this.props.selectedClients.find((item) => item.number === number);

                if (client && client.status) {
                    status = client.status;
                }
            }

            return status && status.length ? status : 'Active';
        };

        const features = cs.Features.filter((x) => this.props.availableFeatures.some((feature) => feature.FeatureId === x.FeatureId)).map(
            (f, index) => {
                const name =
                    'ClientServicesByCountry.' +
                    this.props.countryIndex +
                    '.ParentServices.' +
                    this.props.parentIndex +
                    '.ClientServices.' +
                    this.props.clientIndex +
                    '.Features.' +
                    index +
                    '.IsSelected';
                let display = 'none';

                const features = this.props.availableFeatures;
                for (let i in features) {
                    if (features[i].FeatureId === f.FeatureId && features[i].IsSelected) {
                        display = 'table-cell';
                        break;
                    }
                }

                if (this.state.roleVal === 'CentralAdmin' && f.Name === 'Clearances') {
                    display = 'none';
                }

                const isFeatureDisabled = () => {
                    const featureCode = features[index]?.FeatureCode;

                    // if client account status is inactive -> disable all checkboxes except MLP and Clearances
                    if (featureCode !== global.MLP_FEATURE_CODE && featureCode !== global.CLEARANCES_FEATURE_CODE) {
                        const status = getClientStatus(cs.Number);
                        if (!f.IsSelected && status?.length && status !== 'Active') {
                            return true;
                        }
                    }

                    if (featureCode === global.PAY_FEATURE_CODE) {
                        return this.props.paymentReadOnly;
                    }

                    if (f.IsDisabled || this.props.readonly) {
                        return true;
                    }

                    return false;
                };

                return (
                    <td className='text-center' key={'features' + index} style={{ display: display }}>
                        <CheckBox
                            embed
                            name={name}
                            checked={f.IsSelected}
                            disabled={isFeatureDisabled()}
                            onChecked={this.updateFeature.bind(
                                this,
                                cs.Features.findIndex((x) => features[index].FeatureId === x.FeatureId)
                            )}
                        />
                    </td>
                );
            }
        );

        const removeButton = this.props.readonly ? (
            ''
        ) : (
            <span onClick={this.removeClientService} className='btn btn-link'>
                <i className='text-danger fa fa-times-circle'></i>
            </span>
        );

        const clientAdminDisplay =
            cs.ClientAdminId > 0 ? (
                <span onClick={this.showTeamMembers} className='btn btn-link'>
                    {cs.ClientAdminName}
                </span>
            ) : (
                cs.ClientAdminName
            );

        return (
            <tr>
                <td className='bold'>{cs.Number + cs.CaptionNumber}</td>
                <td>{cs.Name}</td>
                <td>{getClientStatus(cs.Number)}</td>
                <td className={cs.ClientAdminId > 0 ? 'clear-padding' : ''}>{clientAdminDisplay}</td>
                <td>{cs.ServiceCoordinatorName}</td>
                {features}
                <td className='text-right'>{removeButton}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        viewModel: state.accessManagement.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateFeature: (countryIndex, parentIndex, clientIndex, index, isSelected, area) =>
            dispatch(actionCreators.updateFeature(countryIndex, parentIndex, clientIndex, index, isSelected, area)),
        removeClientService: (countryIndex, parentIndex, clientIndex, area) =>
            dispatch(actionCreators.removeClientService(countryIndex, parentIndex, clientIndex, area)),
        showTeamMembers: (clientAdminId, area) => dispatch(actionCreators.showTeamMembers(clientAdminId, area))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientServicesRow);
