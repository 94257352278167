import React, { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CheckBox from '../../common/CheckBox/CheckBox';
import Modal from '../../common/Modal/Modal';
import { teamManagement as Constants, userPermission } from '../../constants/mlp-constants';
import * as actions from '../../store/actions/index';
import TeamGrid from './TeamGrid';

class TeamManagementForm extends Component {
    state = { showActiveUsers: false };

    componentDidMount() {
        this.props.onInitCurrentUserTeamData();
        this.props.onInitTranslations(Constants.INDEX_TRANSLATIONS_URL);
        this.props.onInitUserPermission(userPermission.CENTRALDASHBOARD_ACCESS);
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    componentWillUnmount(){
          this.props.onResetStateTeamManagement();
    }

    addTeamMemberRoleCheck() {
        if (this.props.teamData.model.UserRole === Constants.CLIENTADMIN) {
            return (
                <Link
                    to={{
                        pathname: Constants.NEW_TEAM_MEMBER_LINK
                    }}
                >
                    <div className='btn btn-link' id='add-user-button'>
                        {this.props.translations.Phrases['AddTeamMember_Label']}
                    </div>
                </Link>
            );
        }
    }

    userFilter = (checked) => {
        this.setState({ showActiveUsers: checked });
    };

    closeErrorModal = () => {
        this.props.setError(null);
    };

    dashboardBreadcrumb = () => {
        if (this.props.user.hasCentralPermission) {
            return (
                <Link to='/Clearances' className='breadcrumb-parent'>
                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                </Link>
            );
        } else {
            return (
                <Link to='/Dashboard' className='breadcrumb-parent'>
                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                </Link>
            );
        }
    };

    render() {
        const teamData = this.props.teamData;
        const teamName = teamData.model.TeamName ? teamData.model.TeamName : this.props.translations.Phrases['MyTeam_Label'];
        const canDelete = teamData.model.UserRole === Constants.CLIENTADMIN ? true : false;
        const canClone = teamData.model.UserRole === Constants.CLIENTADMIN ? true : false;
        const pageTitle = this.props.translations.Phrases['Page_Header']
            ? this.props.translations.Phrases['Page_Header'].split(' ')
            : ['', ''];
        return (
            <div className='page-wrapper '>
                <HelmetProvider>
                    <Helmet>
                        <title>{this.props.translations.Phrases['Page_Title']}</title>
                    </Helmet>
                </HelmetProvider>
                <div className='page-top-row'>
                    <div className='page-header-section'>
                        <nav className='mlp-breadcrumb'>
                            {this.dashboardBreadcrumb()}
                            <i className='fa fa-caret-right'></i>
                            <span>{this.props.translations.Phrases['Page_Header_Navigation_Level_2']}</span>
                        </nav>
                    </div>
                    <div className='header-align'>
                        <h1 className='float-left bold-title'>
                            <span>
                                {pageTitle[0]}
                                &nbsp;
                            </span>
                            <span className='gold-title'>{pageTitle[1]}</span>
                        </h1>
                    </div>
                </div>
                <div className='content-wrapper'>
                    <div className='page-content'>
                        <div id='teamManagement'>
                            <Modal
                                isOpen={teamData.Error !== null}
                                onRequestClose={this.closeErrorModal}
                                title={this.props.translations.Phrases['Error_Label']}
                            >
                                <p> {teamData.Error} </p>
                            </Modal>
                            <div className='row w-100 justify-content-between align-items-center team-no-margin'>
                                <div className='col-sm-5'>
                                    {
                                        <h3 className='bold' id='team-name'>
                                            {teamName}
                                        </h3>
                                    }
                                </div>
                                <div className='col-sm-5' id='status-filter'>
                                    <CheckBox
                                        key={teamData.model.ShowAllUsers}
                                        checked={this.state.showActiveUsers}
                                        name='StatusFilter'
                                        text='Show Non-Active Users'
                                        noBorder={true}
                                        onChecked={this.userFilter}
                                    />
                                </div>
                                {this.addTeamMemberRoleCheck()}
                            </div>
                            <TeamGrid
                                teamMembers={teamData.model.TeamMembers}
                                canDelete={canDelete}
                                canClone={canClone}
                                showUsers={this.state.showActiveUsers}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        teamData: state.teamManagement,
        translations: state.translations.model,
        user: state.user.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitCurrentUserTeamData: () => dispatch(actions.initCurrentUserTeamData()),
        onInitTranslations: (phraseGroup) => dispatch(actions.initTranslations(phraseGroup)),
        onInitUserPermission: (permissionName) => dispatch(actions.initUserPermission(permissionName)),
        setError: (value) => dispatch(actions.setTeamMemberError(value)),
        onResetStateTeamManagement: ()=>{dispatch(actions.resetStateTeamManagement())}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamManagementForm);
